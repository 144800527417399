.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  min-height: 40px;
  width: 100%;
  background: #F4F4F4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.title {
  font-size: 16px;
  color: var(--darkGray);
  font-weight: bold;
}

.body {
  height: 100%;
  background: rgb(244, 244, 244);
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tableContainer {
  flex-grow: 1;
  position: relative;
}

.loadingContainer {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}


.confirmContainer {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 16px;
}

.footer {
  display: flex;
  background: rgb(244, 244, 244);
  justify-content: space-between;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 20px 20px 20px;
}

.confirmButton {
  width: 180px;
}