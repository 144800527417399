.container {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    font-family: 'FontAwesome', 'Noto Sans JP', 'Hiragino Sans', serif;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    font-size: 14px;
    color: #D5D5D5;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 0;
    padding-left: 0;
}

.container:focus-within {
    border: 1px solid black;
}

.inputPosIcon {
    width: 15px;
    height: 30px;
    margin-right: 10px;
    background-image: url("../../../public/images/Dropdown-box-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: center;
    cursor: pointer;
}

.inputContainer {
    flex: 1;
    padding-left: 5px;
}

.input {
    height: 30px;
    border: none;
    font-size: 14px !important;
    width: 0;
    min-width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    box-sizing: border-box;
}

.input:focus {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.preInput {
    margin-left: .5rem;
}