.mainFrame {
    padding-top: 74px;
    grid-column-start: 2;
}

.mainFrameBody {
  grid-column-start: 2;
  display: flex;
  overflow: auto;
  height: calc(100vh - 104px);
}

.mainFrameLeftSection {
    width: 218px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #D5D5D5;
}

.mainFrameLeftSectionTop {
    display: flex;
    height: 130px;
    min-height: 130px;
    flex-direction: column;
    padding: 0 10px;
    border-bottom: 1px solid #D5D5D5;
}

.mainFrameLeftSectionTop .title {
    margin-top: 10px;
    align-self: center;
    color: #555555;
    font-size: 20px;
    font-weight: bold;
}

.mainFrameRightSectionTop .title {
    margin-top: 10px;
    align-self: flex-start;
    color: #555555;
    font-size: 20px;
    font-weight: bold;
}

.mainFrameLeftSection .tableHeader {
    border-collapse: collapse;
}

.mainFrameLeftSection .tableHeader tr th {
    color: #555555;
    background: #ffffff;
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid #D5D5D5;
    height: 42px;
    min-height: 42px;
    padding-left: 10px;
}


.mainFrameLeftSectionList {
    height: 100vh;
    overflow-y: scroll;
}

.mainFrameLeftSectionList table {
    width: 100%;
    border-collapse: collapse;
}

.mainFrameLeftSectionList table tbody tr td {
    color: #555555;
    font-size: 13px;
    text-align: left;
    border-bottom: 1px solid #D5D5D5;
    padding: 4px 0 4px 12px;
}

.tableName {
  width: 100%;
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.mainFrameLeftSectionList::-webkit-scrollbar-thumb {
    background: #d5d5d5;
    border-radius: 6px;
    border: 3px solid white;
}

.mainFrameLeftSectionList::-webkit-scrollbar {
    width: 10px;
    background: #f2f2f2;
    /*border-left: 1px solid #D5D5D5;*/
}

.mt10 {
    margin-top: 10px;
}

.mr10 {
    margin-right: 10px;
}

.searchInput input {
    height: 26px;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    font-family: 'FontAwesome', 'Noto Sans JP', 'Hiragino Sans', serif;
    padding-left: 10px;
    font-size: 14px;
    color: #D5D5D5;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
}

.searchInput input::placeholder {
    color: #D5D5D5;
}

.tableHeader {
    border-collapse: collapse;
}

.tableHeader tr th {
    color: #555555;
    background: #f2f2f2;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #D5D5D5;
    /*padding: 0 12px;*/
    height: 42px;
    min-height: 42px;
}

.tableHeader tr .thFilter {
    width: 12px !important;
    min-width: 12px;
    background: #54C3F1;
    background-image: url('../../../public/images/Icon-awesome-filter.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    padding: 0 2px;
    border-left: 1px solid #D5D5D5;
}

.borderRight {
    margin-right: 0;
    justify-self: end;
    border-right: 1px solid #D5D5D5;
}

.mainFrameRightSection {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.mainFrameRightSectionTop {
    display: flex;
    height: 130px;
    min-height: 130px;
    width: 100%;
    flex-direction: column;
    padding: 0 10px;
    border-bottom: 1px solid #D5D5D5;
}

.mainFrameRightSectionTopEmpty {
  height: 130px;
  min-height: 130px;
}

.topInput {
    font-size: 14px;
    height: 30px;
    width: 198px;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    padding-left: 7px;
    box-sizing: border-box;
}

.topButton {
    height: 30px;
    width: 198px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1px;
}

.mainFrameRightSectionTopButtons {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.mainFrameRightSectionFooterButtons {
  border-top: 1px solid #D5D5D5;
  justify-content: space-between;
  min-height: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.mainFrameRightSectionList {
    width: 100%;
    height: 100%;
}

.mainFrameRightSectionListEmpty {
  height: 100%;
}


.select {
    font-size: 14px;
    width: 200px;
    height: 30px;
}

