
.topBar {
    border-bottom: 1px solid #D5D5D5;
    background-color: white;
    position: fixed;
    left: 63px;
    right: 0;
    height: 74px;
    display: flex;
    z-index: 10;
}

.topBarIsSwitching {
    background-color: #bae3f9;
}

.searchBar {
    background-color: rgb(242, 242, 242);
    align-self: center;
    flex: 4;
    height: 46px;
    padding-left: 14px;
    padding-right: 26px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topbarLeftSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.topbarRightsection {
    flex: 3;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 16px;
}

.topbarRightsection .topButtonMain {
    margin-right: 16px;
    width: 46px;
}
.topbarRightsectionImg {
    width: 100%;
}

.topButtonMain {
    margin: 0px 7px;
    height: 46px;
}
.topButtonMain button {
    cursor: pointer;
    width: 55px;
    height: 46px;
    background-color: white;
    border-radius: 8px;
    border: none;
}
.topButtonMain img {
    height: 100%;
    object-fit: contain;
}

.switch-status-bar {
    max-height: 46px;
    display: flex;
    align-items: center;
    border: 1px solid;
    margin-right: 3.2rem;
    padding: 0.6rem;
    background: #fce4d6;
    border-color: #ed7d31;
    cursor: pointer;
}

.topbarCartBadge {
    background-color: var(--red);
    color: var(--white);
    /* border-radius: 3px !important; */
}