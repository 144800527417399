.tabItem {
  margin-bottom: 15px;
}

.tabItemCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 40px;
  margin-bottom: 10px;
}

.tabItemSubTitle {
  color: var(--gray);
  font-size: 16px;
  margin-bottom: 7px;
}

.tabCardRow {
  padding: 0 20px;
  display: flex;
  font-size: 14px;
  color: var(--gray);
}

.tabItemTitle {
  font-size: 16px;
  color: var(--darkGray);
  margin-bottom: 5px;
}
