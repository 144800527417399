
.mainframe {
  padding-top: 74px;
  grid-column-start: 2;
  overflow: auto;
}

.mainFrameBody {
  grid-column-start: 2;
  display: flex;
  overflow: auto;
  height: calc(100vh - 104px);
}

.mainframe-leftsection {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #D5D5D5;
}

.mainframe-leftsection > header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
}

.mainframe-leftsection > div {
    margin: 20px 10px 0 10px;
    display: flex;
    width: 360px;
}

.date-field > input:last-child {
    width: 60% !important;
    margin-left: 10px;
}

.date-field > label {
    padding-left: 20px;
    font-size: 14px !important;
}

.mainframe-leftsection label {
    font-size: 16px;
    color: var(--darkGray);
    width: 120px;
    min-width: 120px;
    margin-right: 20px;
}

.mainframe-leftsection > footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    border-top: var(--defaultBorder);
}

.mainframe-rightsection {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.mainframe-rightsection-top {
    min-height: 50px;
    padding:10px;
    border-bottom: 1px solid #D5D5D5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.table-header {
    border-collapse: collapse;
}

.table-header tr th {
    color: #555555;
    background: #f2f2f2;
    font-size: 14px;
    text-align: start;
    border-bottom: 1px solid #D5D5D5;
    height: 45px;
    min-height: 45px;
}

.mainframe-rightsection-list {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.mainframe-rightsection-list table {
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
}

.mainframe-rightsection-list table tbody tr td {
    color: #333333;
    font-size: 12px;
    border-bottom: 1px solid #D5D5D5;
    height: 45px;
    min-height: 45px;
}

.mainframe-rightsection-column-1 {
    width: 60px !important;
    min-width: 60px !important;
    padding-left: 10px;
}

.mainframe-rightsection-column-2 {
    width: 170px !important;
    min-width: 170px !important;
}

.mainframe-rightsection-column-3 {
    width: 220px !important;
    min-width: 220px !important;
}

.mainframe-rightsection-column-4 {
    width: 220px !important;
    min-width: 220px !important;
}

.mainframe-rightsection-column-5 {
    width: 170px !important;
    min-width: 170px !important;
}

.mainframe-rightsection-column-6 {
    width: 220px !important;
    min-width: 220px !important;
}

.mainframe-rightsection-column-7 {
    width: 170px !important;
    min-width: 170px !important;
}

.top-button {
    height: 30px;
    width: 198px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1px;
}

.w-100 {
    width: 100% !important;
}

.paginationButtonsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    margin-right: 20px;
}

.paginationPreviousNextButton {
    /*background: red;*/
    width: 15px;
    height: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333333;
}

.paginationButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--defaultBorder);
    padding: 2px;
    border-radius: 4px;
}

.tableButton {
    font-size: 12px;
    height: 26px;
    width: 80px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.tableButtonDisabled {
    font-size: 12px;
    height: 26px;
    width: 80px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: default;
    color: white;
}

.customColumnLastHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 30px;
}