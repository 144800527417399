.bodySection {
    height: 100%;
    padding: 30px 0 30px 30px;
    overflow-y: hidden;
}

.bodySectionMain {
    height: 100%;
    overflow-y: auto;
    margin-top: 10px;
}

.bodySection .topButtonsImg {
    width: 30px;
    height: 30px;
    margin-right: 45px;
    cursor: pointer;
}

.imagesContainerSection {
    margin-top: 10px;
    width: calc(100% - 30px) !important;
    height: 300px;
}

.arrowImg {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 60px;
    top: 10px;
    cursor: pointer;
}

.pdfContainer {
    position: relative;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
    min-width: 120px;
}

.text {
    font-size: 14px;
    color: var(--gray);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tagTextContent {
    width: calc(100% - 140px);
    padding-right: 30px
}


.bodySection ::-webkit-scrollbar-thumb {
    color: #D5D5D5;
    background: #d5d5d5;
    border-radius: 6px;
    border-left: 3px solid rgba(0, 0, 0, 0);
    border-right: 2px solid rgba(0, 0, 0, 0);
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

.bodySection ::-webkit-scrollbar {
    width: 17px;
    border-radius: 4px;
    background: var(--white);
    border-left: 1px solid #D5D5D5;
}

.formContainer {
    width: 675px;
    /*background: red;*/
}

.tagBottomContainer {
    height: 100px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000010;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
}

.tagBottomContainer img {
    width: 16px;
    height: 16px;
}

.borderBottomLightGray {
    border-bottom: 1px solid #C1C1C1;
}

.text12px {
    font-size: 12px;
}

.textdark {
    color: var(--darkGray);
}

.textlight {
    color: #777777;
}

.textArea {
    width: 100%;
    height: 60px;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    resize: none;
    background: var(--white);
    box-sizing: border-box;
    font-size: 14px;
    color: var(--gray);
}

.textArea::placeholder {
    font-size: 12px;
    color: #d5d5d5;
    padding-left: 10px;
}

.footerButton {
    width: 100%;
    float: right;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
}

.width280px {
    width: 280px;
}

.formSelect input {
    width: 245px;
}

.formInput {
    width: 280px !important;
}

.formInput input {
    width: 280px !important;
}

.disabledColor {
    background-color: #F0F0F0;
}

.alertMessage {
    padding: 50px 50px 0 50px;
    font-size: 14px;
    color: #555555;
    text-align: center;

}

.textCenter {
    text-align: center;
}

.relationalDocumentDetail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 96%;
}

.relationalDocumentDetail>div {
    width: 30%;
    font-size: 14px;
    color: var(--gray);
}

.tagsDiv {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 250px;
    margin-right: 20px;
}

.deleteRevisionButtonWidth {
    width: 200px;
}

.deletedTextStyle {
    text-decoration: line-through;
    color: #A8A8A9;
}
