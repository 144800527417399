.mainFrame {
    padding-top: 74px;
    height: 100vh;
    grid-column-start: 2;
    display: flex;
}

.mainFrameBody {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;
    height: calc(100vh - 74px);
}

.contentBox {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding: 30px 30px 130px 30px;
}

.mainFrameHeader {
  min-height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid #D5D5D5;
}