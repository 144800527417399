
.container {
    grid-column-start: 2;
}

.mainContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.lowerRow {
  display: flex;
  width: 1278px;
  min-height: 293px;
  justify-content: flex-start;
  align-items: center;
}