
.mainframe-body-footer-buttons {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.modal-header {
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #f4f4f4;
}

.modal-body {
    padding: 20px 20px 40px 20px;
    display: flex;
    height: 90vh;
}

.modal-body-left {
    min-width: 250px;
    width: 150px;
}

.modal-body-right {
    flex: 1;
}

.modal-footer{
    margin-top: auto;
}

.notification-email-settings {
    max-width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 30px 20px 30px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: hidden;
    text-align: left;
}

.tagName {
    margin-left: 33px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    max-width: calc(100% - 90px);
}

.tagLabel {
    min-width: 60px;
}

.text {
    font-size: 14px;
    color: var(--darkGray);
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin-right: 5px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(14px);
}

.title-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.title {
    color: var(--gray);
    font-size: 18px;
    font-weight: bold;
}

.checkbox-group {
    border: black 1px solid;
    height: 90%;
}

.checkbox-item input {
    cursor: pointer;
}

.checkbox-item label {
    cursor: pointer;
}

.days-input {
    width: 53%;
    height: 30px;
    font-size: 14px;
    color: #555555;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    padding-left: 7px;
    box-sizing: border-box;
}

.search-input-container {
    margin-top: 10px;
    margin-left: 20px;
    max-width: 270px;
}

.searchContainer {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 6.5px;
}

.clearSearchButton {
    position: absolute;
    right: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.divider {
    margin-left: 20px;
    margin-right: 20px;
}

.days-input::-webkit-inner-spin-button,
.days-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.days-input {
    -moz-appearance: textfield;
}