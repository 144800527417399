.bodySection {
    height: calc(100% - 35px);
    margin: 35px 0 0 0;
    overflow-y: hidden;
}

.bodySectionMain {
    height: 100%;
    overflow-y: auto;
    margin-top: 10px;
}

.bodySection .topButtonsImg {
    width: 30px;
    height: 30px;
    margin-right: 30px;
}

.card {
    border-left: var(--defaultBorder);
    border-right: var(--defaultBorder);
    border-bottom: var(--defaultBorder);
    box-shadow: 0 3px 6px #00000010;
    border-radius: 4px;
    background: #ffffff;
}

.cardTitle {
    border-top: var(--defaultBorder);
    height: 30px;
    background: var(--primaryLight);
    border-radius: 4px;
    font-size: 14px;
    color: var(--darkGray);
}

.cardSubTitle {
    height: 25px;
    background: #F4F4F4;
    border-radius: 3px;
    font-size: 14px;
    color: var(--darkGray);
    margin-bottom: 8px;
    padding-bottom: 3px;
}
 .cardBody {
     height: 22px;
     font-size: 12px;
     color: var(--darkGray);
 }

.collapse {
    cursor: pointer;
}

.collapseContent {
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    margin-bottom: 10px;
}

.detailButtonHeader {
    display: flex;
    justify-content: flex-end;
}

.detailButtonHeader > span {
    margin-right: 22px;
}

.detailButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.tableButton {
    font-size: 10px;
    height: 18px;
    width: 40px;
    border-radius: 5px;
    margin: 0 15px;
    cursor: pointer;
}

.tableButtonDisabled {
    font-size: 10px;
    height: 18px;
    width: 40px;
    border-radius: 5px;
    margin: 0 15px;
    cursor: default;
    color: white;
}