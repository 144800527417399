
.mainFrame {
  padding-top: 74px;
  grid-column-start: 2;
  overflow: auto;
  height: 100vh;
}

.mainFrameBody {
  grid-column-start: 2;
  display: flex;
  overflow: auto;
  height: calc(100% - 30px);
}

.mainFrameLeftSection {
    width: 218px;
    min-width: 218px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--border-gray);
}

.mainFrameLeftSectionTop {
    display: flex;
    height: 130px;
    min-height: 130px;
    flex-direction: column;
    padding-bottom:10px;
    border-bottom: 1px solid var(--border-gray);
    box-sizing: border-box;
}

.mainFrameLeftSectionHeader {
    height: 40px;
    min-height: 40px;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
}

.mainFrameLeftSectionList {
    height: 100%;
    overflow-y: auto;
}

.mainFrameLeftSectionListRow {
    height: 26px;
    color: var(--darkGray);
    font-size: 13px;
    border-bottom: var(--defaultBorder);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mainFrameLeftSectionListRow img {
    width: 12px;
    height: 12px;
}

.mainFrameLeftSectionUpperFooter {
    border-top: var(--defaultBorder);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.mainFrameLeftSectionUpperFooterRow {
    height: 100%;
    width: 100%;
    display: flex;
}

.mainFrameLeftSectionFooter {
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    border-top: var(--defaultBorder);
    display: flex;
    align-items: center;
    padding-left: 14px;
    padding-right: 13px;
    /*justify-content: space-around;*/
}

.mainFrameLeftSectionFooter button {
    width: 90px;
}

.mainFrameRightSectionCols {
    width: 100%;
    height: 100%;
    display: flex;
}

.mainFrameRightSectionCol1 {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.mainFrameRightSectionCol1Header {
    height: 40px;
    min-height: 40px;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: var(--defaultBorder);
}

.mainFrameRightSectionCol1Body {
    overflow-y: auto;
    border-right: var(--defaultBorder);
    height: 100%;
    padding: 20px;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
    width: 130px;
    min-width: 130px;
}

.width280px {
    width: 280px;
}

.width280pxImportant {
    width: 280px !important;
}

.datePicker {
    width: 280px
}
.datePicker input{
    width: 280px
}

.disabledColor {
    background-color: #F5F5F5;
}

.textArea {
    width: 100%;
    height: 60px;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    resize: none;
    background: var(--white);
    box-sizing: border-box;
    font-size: 14px;
    font-family: auto;
}

.textArea::placeholder {
    font-size: 12px;
    color: #d5d5d5;
    padding-left: 10px;
}

.text {
    font-size: 14px;
    color: var(--gray);
}

.tagTextContent {
    width: calc(100% - 110px);
}

.tagBottomContainer {
    height: 100px;
    background: var(--primaryLight);
    box-shadow: 0 3px 6px #00000010;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    width: 550px;
    float: right;
}

.tagBottomContainer img {
    width: 16px;
    height: 16px;
}

.text12px {
    font-size: 12px;
}

.borderBottomLightGray {
    border-bottom: 1px solid #C1C1C1;
}

.textDark {
    color: var(--darkGray);
}

.text12px {
    font-size: 12px;
}

.textLight {
    color: #777777;
}

.mainFrameRightSection {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.mainFrameRightSectionCol2 {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.mainFrameRightSectionCol2Header {
    height: 40px;
    min-height: 40px;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: var(--defaultBorder);
}

.mainFrameRightSectionCol2Header select{
    width: 165px;
    background-color: #f4f4f4;
}

.pipeSeparator {
    height: 70%;
    border-right: var(--defaultBorder);
    border-left: var(--defaultBorder);
}


.mainFrameRightSectionCol2Body {
    overflow-y: scroll;
    overflow-x: auto;
    background: #d5d5d5;
    flex: 1;
}

.pdfContainer {
    height: 100%;
    width: 100%;
}

.mainFrameRightSectionCol2BodyContent {
    height: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    padding-top: 20px;
}

.mainFrameRightSectionCol2BodyContent img {
    height: calc(100% - 40px);
    object-fit: contain;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.filter {
    width: 10px !important;
    min-width: 10px;
    max-width: 10px;
    height: 100%;
    background: #54C3F1;
    background-image: url('../../../public/images/Icon-awesome-filter.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    padding: 0 2px;
    border-left: 1px solid #D5D5D5;
    position: absolute;
    right: 0;
    top: 0;
}

.button {
  cursor: pointer;
}

.relationalDocumentDetail {
    display: flex;
    display: -webkit-box;
    margin-bottom: 10px;
    margin-left: 12px;
}

.relationalDocumentDetail > div {
    font-size: 14px;
    color: var(--gray);
}

.tagsDiv {
    width: 250px;
    margin-right: 20px;
}

.spinnerContainer {
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    z-index: 9999;
    height: 94%;
    display: flex;
    justify-content: center;
}

.textTag {
    font-size: 14px;
    color: var(--gray);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    width: 170px;
    min-width: 170px;
}
