.modalOverlay {
    display: none;
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.5);
}

.alertBox {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    /*height: calc(100% - 100px);*/
    /*padding: 50px 0;*/
}

.modal {
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000010;
    background: #FFFFFF;
    position: fixed;
    color: #555555;
    text-align: center;
    width: 75%;
    top: 25px;
    bottom: 25px;
    left: 12.5%;
    right: 12.5%;
    overflow: auto;
}

.topButtonsImg {
    width: 30px;
    height: 30px;
    margin: 5px 5px 5px auto;
    cursor: pointer;
}

.modalContainer {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #F4F4F4;
}

.modalHeader {
    height: 40px;
    min-height: 40px;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: var(--defaultBorder);
    border-top: var(--defaultBorder);
}

.pipeSeparator {
    height: 70%;
    border-right: var(--defaultBorder);
    border-left: var(--defaultBorder);
}

.modalBody {
    overflow-y: hidden;
    overflow-x: auto;
    flex: 1;
    background: #EFEFEF;
}

.pdfContainer {
    height: 100%;
    width: 100%;
}

.modalFooter {
    background: #F4F4F4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-top: var(--defaultBorder);
    padding: 5px;
}

.minusButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    cursor: pointer;
}

