.copyContainer {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    min-width: 25px;
    min-height: 25px;
}

.copiedMessage {
    position: absolute;
    left: 100%;
    white-space: nowrap;
    margin-left: 8px;
    background-color: #54C3F1;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.75rem;
    color: white;
}

.copyContainer {
    transition: background-color 0.2s;
}

.copyContainer:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.copyContainer:active,
.copyContainer.clicked {
    background-color: rgba(0, 0, 0, 0.2);
}
