.mainFrameRightSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: calc(100vw - 300px);
}

.mainFrameRightSectionTopLine {
  height: 30px;
  min-height: 30px;
  border-bottom: 1px solid #D5D5D5;
  flex: 1;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.homeText {
  font-size: 12px;
  color: #c1c1c1;
  margin-right: 10px;
}

.topLineOptions {
  font-size: 12px;
  color: #333333;
  margin-left: 10px;
}

.mainFrameRightSectionTop {
  display: flex;
  height: 50px;
  min-height: 50px;
  flex-direction: column;
  padding: 0 10px;
  border-bottom: 1px solid #D5D5D5;
}

footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: var(--defaultBorder);
}