.input {
    font-size: 14px;
    color: #555555;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    height: 30px;
    padding-left: 7px;
    box-sizing: border-box;
    width: 100% !important;
}

.disabled {
    background: #F5F5F5;
}
