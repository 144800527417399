code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ag-root ::-webkit-scrollbar:horizontal {
  height: 16px;
  border-top: var(--defaultBorder);
  border-left: none;
}

.Toastify__toast-theme--colored.Toastify__toast--default {
  background: #54C3F1; /* primary */
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  white-space: pre-line;
}

.Toastify__toast-container--top-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.m-1 {
  margin: 0.25em;
}

.m-2 {
  margin: 0.5em;
}

.m-3 {
  margin: 1em;
}

.m-4 {
  margin: 1.5em;
}

.m-5 {
  margin: 3em;
}

.mt-1 {
  margin-top: 0.25em;
}

.mt-2 {
  margin-top: 0.5em;
}

.mt-3 {
  margin-top: 1em;
}

.mt-4 {
  margin-top: 1.5em;
}

.mt-5 {
  margin-top: 3em;
}

.mb-1 {
  margin-bottom: 0.25em;
}

.mb-2 {
  margin-bottom: 0.5em;
}

.mb-3 {
  margin-bottom: 1em;
}

.mb-4 {
  margin-bottom: 1.5em;
}

.mb-5 {
  margin-bottom: 3em;
}

.mr-1 {
  margin-right: 0.25em;
}

.mr-2 {
  margin-right: 0.5em !important;
}

.mr-3 {
  margin-right: 1em;
}

.mr-4 {
  margin-right: 1.5em;
}

.mr-5 {
  margin-right: 3em;
}

.ml-1 {
  margin-left: 0.25em;
}

.ml-2 {
  margin-left: 0.5em;
}

.ml-3 {
  margin-left: 1em;
}

.ml-4 {
  margin-left: 1.5em;
}

.ml-5 {
  margin-left: 3em;
}

.p-1 {
  padding: 0.25em;
}

.p-2 {
  padding: 0.5em;
}

.p-3 {
  padding: 1em;
}

.p-4 {
  padding: 1.5em;
}

.p-5 {
  padding: 3em;
}

.pt-1 {
  padding-top: 0.25em;
}

.pt-2 {
  padding-top: 0.5em;
}

.pt-3 {
  padding-top: 1em;
}

.pt-4 {
  padding-top: 1.5em;
}

.pt-5 {
  padding-top: 3em;
}

.pb-1 {
  padding-bottom: 0.25em;
}

.pb-2 {
  padding-bottom: 0.5em;
}

.pb-3 {
  padding-bottom: 1em;
}

.pb-4 {
  padding-bottom: 1.5em;
}

.pb-5 {
  padding-bottom: 3em;
}

.pr-1 {
  padding-right: 0.25em;
}

.pr-2 {
  padding-right: 0.5em;
}

.pr-3 {
  padding-right: 1em;
}

.pr-4 {
  padding-right: 1.5em;
}

.pr-5 {
  padding-right: 3em;
}

.pl-1 {
  padding-left: 0.25em;
}

.pl-2 {
  padding-left: 0.5em;
}

.pl-3 {
  padding-left: 1em;
}

.pl-4 {
  padding-left: 1.5em;
}

.pl-5 {
  padding-left: 3em;
}

.p-20px {
    padding: 20px;
}

.pl-20px {
    padding-left: 20px;
}

.col-1 {
  width: 8.333333%;
}
.col-2 {
  width: 16.666666%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.333333%;
}
.col-5 {
  width: 41.66666%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.333333%;
}
.col-8 {
  width: 66.666666%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.333333%;
}
.col-11 {
  width: 91.66666%;
}
.col-12 {
  width: 100%;
}

.d-inline {
  display: inline;
}

.d-flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}

.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}
.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.clickable {
  cursor: pointer;
}


.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}
