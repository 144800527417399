.sideCardItem {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--lightGray);
  margin-bottom: 10px;
}

.sideCardItem label {
  margin-bottom: 10px;
}

.sideCardItemRow {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.sideCardItemRow:first-child {
  margin-bottom: 20px;
}

.flexCol, .inputCol, .formatCol {
  display: flex;
  flex-direction: column;
}

.autoCompleteBox {
  flex: 1;
  width: 100%;
}

.inputCol {
  width: 100%;
}

.formatCol {
  padding: 0 10px;
  width: 80px;
  height: 100%;
}

.formatLabel {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formatText {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tagInput {
  width: 100%;
}

.deleteCardButton {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.errorContainer {
  height: 30px;
  display: flex;
  align-items: center;
  color: var(--error);
}