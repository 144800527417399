:root {
    --border-gray: #d5d5d5;
    --defaultBorder: 1px solid #d5d5d5;
    --input-placeholder-color: #C1C1C1;
    --primary: #54C3F1;
    --primaryLight: #bae3f9;
    --success: #6CAC55;
    --successLight: #96D45C;
    --danger: #e60012;
    --error: #E50019;
    --warning: #FFCC00;
    --darkGray: #333333;
    --gray: #555555;
    --midGray: #B2B2B2;
    --lightGray: #D2D2D2;
    --lighterGray: #E5E5E5;
    --white: #FFFFFF;
    --black: #000000;
    --red: #E50019;
    --text-font-size: 14px;
}

.pt-74px {
    padding-top: 74px;
}

.m-1 {
    margin: 0.25em;
}

.m-2 {
    margin: 0.5em;
}

.m-3 {
    margin: 1em;
}

.m-4 {
    margin: 1.5em;
}

.m-5 {
    margin: 3em;
}

.mt-1 {
    margin-top: 0.25em;
}

.mt-2 {
    margin-top: 0.5em;
}

.mt-3 {
    margin-top: 1em;
}

.mt-4 {
    margin-top: 1.5em;
}

.mt-5 {
    margin-top: 3em;
}

.mb-1 {
    margin-bottom: 0.25em;
}

.mb-2 {
    margin-bottom: 0.5em;
}

.mb-3 {
    margin-bottom: 1em;
}

.mb-4 {
    margin-bottom: 1.5em;
}

.mb-5 {
    margin-bottom: 3em;
}

.mr-1 {
    margin-right: 0.25em;
}

.mr-2 {
    margin-right: 0.5em !important;
}

.mr-3 {
    margin-right: 1em;
}

.mr-4 {
    margin-right: 1.5em;
}

.mr-5 {
    margin-right: 3em;
}

.ml-1 {
    margin-left: 0.25em;
}

.ml-2 {
    margin-left: 0.5em;
}

.ml-3 {
    margin-left: 1em;
}

.ml-4 {
    margin-left: 1.5em;
}

.ml-5 {
    margin-left: 3em;
}

.p-1 {
    padding: 0.25em;
}

.p-2 {
    padding: 0.5em;
}

.p-3 {
    padding: 1em;
}

.p-4 {
    padding: 1.5em;
}

.p-5 {
    padding: 3em;
}

.pt-1 {
    padding-top: 0.25em;
}

.pt-2 {
    padding-top: 0.5em;
}

.pt-3 {
    padding-top: 1em;
}

.pt-4 {
    padding-top: 1.5em;
}

.pt-5 {
    padding-top: 3em;
}

.pb-1 {
    padding-bottom: 0.25em;
}

.pb-2 {
    padding-bottom: 0.5em;
}

.pb-3 {
    padding-bottom: 1em;
}

.pb-4 {
    padding-bottom: 1.5em;
}

.pb-5 {
    padding-bottom: 3em;
}

.pr-1 {
    padding-right: 0.25em;
}

.pr-2 {
    padding-right: 0.5em;
}

.pr-3 {
    padding-right: 1em;
}

.pr-4 {
    padding-right: 1.5em;
}

.pr-5 {
    padding-right: 3em;
}

.pl-1 {
    padding-left: 0.25em;
}

.pl-2 {
    padding-left: 0.5em;
}

.pl-3 {
    padding-left: 1em;
}

.pl-4 {
    padding-left: 1.5em;
}

.pl-5 {
    padding-left: 3em;
}

.col-1 {
    width: 8.333333%;
}
.col-2 {
    width: 16.666666%;
}
.col-3 {
    width: 25%;
}
.col-4 {
    width: 33.333333%;
}
.col-5 {
    width: 41.66666%;
}
.col-6 {
    width: 50%;
}
.col-7 {
    width: 58.333333%;
}
.col-8 {
    width: 66.666666%;
}
.col-9 {
    width: 75%;
}
.col-10 {
    width: 83.333333%;
}
.col-11 {
    width: 91.66666%;
}
.col-12 {
    width: 100%;
}

.px-10px {
    padding-right: 10px;
    padding-left: 10px;
}

.p-10px {
    padding: 10px;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: end !important;
}

.text-small {
    font-size: 11px;
}

.text-bold {
    font-weight: bold;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}



.text-danger {
    color: var(--danger) !important;
}

.float-right {
    float: right;
}

.px-25 {
    padding-left: 25px;
    padding-right: 25px;
}

.px-50 {
    padding-left: 50px;
    padding-right: 50px;
}

.btn {
    border-radius: 7px;
    padding: 8px 80px 8px 80px;
    font-size: 18px;
    font-weight: 700;
}

.btn-gray {
    color: #555555;
    background-color: #e5e5e5 !important;
    border: 1px solid #D5D5D5 !important;
}

.btn-light {
    color: #333333;
    background-color: #ffffff !important;
    border: var(--defaultBorder) !important;
}

.btn-primary {
    color: #fff !important;
    background-color: #54C3F1 !important;
    border: 1px solid #54C3F1 !important;
}

.btn-primary-light {
    color: #fff !important;
    background-color: var(--primaryLight) !important;
    border: 1px solid var(--primaryLight) !important;
}

.btn-danger {
    color: #fff !important;
    background-color: var(--danger) !important;
    border: 1px solid #E60012 !important;
}

.btn-success {
    color: #fff !important;
    background-color: var(--success) !important;
    border: 1px solid var(--success) !important;
}

.btn-warning {
    color: #fff !important;
    background-color: #FCB712 !important;
    border: 1px solid #FCB712 !important;
    font-weight: unset;
}

.btn-outline-primary {
    color: #54C3F1 !important;
    border-color: #54C3F1 !important;
    background-color: #fff !important;
    border-style: solid;
}

.btn-sm {
    border-radius: 5px;
    padding: 6px 60px 6px 60px;
    font-size: 14px;
    border-width: 1px;
    border-color: rgb(191, 191, 191);
}

.btn-xs {
    padding: 0 !important;
    font-weight: 500 !important;
    width: 141px;
    height: 30px;
    font-size: 16px !important;
    border-radius: 4px !important;
    border-color: #d5d5d5;
    background: #e5e5e5;
    color: #555555;
}

.button {
    width: 141px;
    height: 30px;
    font-size: 14px;
    border-radius: 4px;
    border-color: #d5d5d5;
    background: #e5e5e5;
    color: #555555;
    cursor: pointer;
}
.small-button {
    width: 70px !important;
}


.table-button {
    font-size: 10px;
    border-radius: 8px;
    background: #f2f2f2;
    color: #777777;
    border: 1px solid #f2f2f2;
    width: 40px;
    height: 20px;
    cursor: pointer;
}

.ml-20px {
    margin-left: 20px;
}

.ml-30px {
    margin-left: 30px;
}

.mr-20px {
    margin-right: 20px;
}

.mt-15px {
    margin-top: 15px;
}

.mt-20px {
    margin-top: 20px;
}

.mb-15px {
    margin-bottom: 15px;
}

.mb-20px {
    margin-bottom: 20px;
}

.mt-30px {
    margin-top: 30px;
}

.mb-30px {
    margin-bottom: 30px;
}

.my-30px {
    margin-top: 30px;
    margin-bottom: 30px;
}

.my-20px {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mx-10px {
    margin-left: 10px;
    margin-right: 10px;
}

.d-inline {
    display: inline;
}

.d-flex {
    display: flex;
}

.wrap {
    flex-wrap: wrap;
}

.p-relative {
    position: relative;
}
.p-absolute {
    position: absolute;
}

.flex-column {
    flex-direction: column;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.select {
    height: 30px;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    padding-left: 10px;
    font-size: 14px;
    color: #555555;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    background-image: url("../../public/images/Dropdown-box-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: center;
}

.select-date {
    background-image: url("../../public/images/Icon-feather-calendar.svg") !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.input {
    font-size: 14px;
    color: #555555;
    width: 100%;
    height: 30px;
    min-height: 30px;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    padding-left: 7px;
    box-sizing: border-box;
}

.textareaAutosize {
    font-size: 14px;
    color: #555555;
    width: 100%;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    padding-left: 4px;
    box-sizing: border-box;
    resize: none; /* Prevent manual resizing */
    font-family: auto;
}

.input-date {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    background-image: url("../../public/images/Icon-feather-calendar.svg") !important;
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: center;
}

.date-picker-input {
    font-size: 14px;
    color: #555555;
    /*width: 100%;*/
    width: 130px;
    height: 30px;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    padding-left: 7px;
    box-sizing: border-box;
}

.time-picker-input {
    font-size: 14px;
    color: #555555;
    /*width: 100%;*/
    width: 80px;
    height: 30px;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    padding-left: 7px;
    box-sizing: border-box;
}

input::placeholder {
    color: var(--input-placeholder-color);
}

.input-search {
    font-family: 'FontAwesome', 'Noto Sans JP', 'Hiragino Sans', serif;
}

.mainframe-leftsection-list::-webkit-scrollbar {
    width: 8px;
    background: var(--white);
    border-left: 1px solid #D5D5D5;
}

.mainframe-leftsection-list::-webkit-scrollbar-thumb {
    color: #D5D5D5;
    background: #d5d5d5;
    border-radius: 3px;
    border-left: 2px solid rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0);
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb {
    color: #D5D5D5;
    background: #d5d5d5;
    border-radius: 6px;
    border-left: 3px solid rgba(0, 0, 0, 0);
    border-right: 2px solid rgba(0, 0, 0, 0);
    border-top: 3px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

::-webkit-scrollbar {
    width: 16px;
    background: var(--white);
    border-left: var(--defaultBorder);
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.add-user-group-button-width {
    width: 180px;
}

.space-between {
    justify-content: space-between;
}

.text-bold {
    font-weight: bold;
}

.border-bottom {
    border-bottom: var(--defaultBorder) !important;
}

.border-top {
    border-top: var(--defaultBorder);
}

.border-left {
    border-left: var(--defaultBorder);
}

.border-right {
    border-right: var(--defaultBorder);
}

.border {
    border: var(--defaultBorder);
}

.text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-area {
    width: 60%;
    max-width: 600px;
    height: 60px;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    resize: none;
    background: var(--white);
    padding: 5px 10px;
    color: #555555;
    font-size: 14px;
    font-family: Arial;
}

textarea::placeholder {
    color: var(--input-placeholder-color);
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.expandDiv {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
}

.disabled-input-color {
    background-color: #F5F5F5;
}