.mainframe {
    padding-top: 74px;
    grid-column-start: 2;
    overflow: auto;
}

.mainFrameBody {
  grid-column-start: 2;
  display: flex;
  overflow: auto;
  height: calc(100vh - 104px);
}

.mainframe-leftsection {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #D5D5D5;
}

.leftsection-title-line {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.leftsection-title {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
}

.leftsection-inputfield {
    margin: 20px 10px 0 10px;
    display: flex;
    width: 330px;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
    width: 250px;
    margin-right: 20px;
}

.leftsection-footer-buttons {
    margin-top: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-top: var(--defaultBorder);
}

.mainframe-rightsection {
    flex: 1;
    display: flex;
    flex-direction: column;
    /*overflow: auto;*/

}

.mainframe-rightsection-top {
    display: flex;
    height: 50px;
    min-height: 50px;
    flex-direction: column;
    padding: 0 10px;
    border-bottom: 1px solid #D5D5D5;
}

.mainframe-rightsection-top-buttons {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.mainframe-rightsection-top-buttons .downloadButton{
    margin-left: auto;
}

.table-header {
    border-collapse: collapse;
}

.table-header tr th {
    color: #555555;
    background: #f2f2f2;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #D5D5D5;
    height: 42px;
    min-height: 42px;
}

.mainframe-rightsection-list {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.mainframe-rightsection-list table {
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
}

.mainframe-rightsection-list table tbody tr td {
    color: #333333;
    font-size: 12px;
    text-align: center;
    border-bottom: 1px solid #D5D5D5;
    height: 45px;
    min-height: 45px;
    line-height: 17px;
    box-sizing: border-box;
}

.mainframe-rightsection-column-1 {
    width: 36px !important;
    min-width: 36px !important;
}

.mainframe-rightsection-column-2 {
    width: 120px !important;
    min-width: 120px !important;
    text-align: left !important;
    margin-left: 1em !important;
}

.mainframe-rightsection-column-3 {
    width: 100px !important;
    min-width: 100px !important;
}

.mainframe-rightsection-column-4 {
    width: 150px !important;
    min-width: 150px !important;
}

.mainframe-rightsection-column-5 {
    width: 150px !important;
    min-width: 150px !important;
}

.mainframe-rightsection-column-6 {
    width: 150px !important;
    min-width: 150px !important;
}

.mainframe-rightsection-column-7 {
    width: 150px !important;
    min-width: 150px !important;
}

.mainframe-rightsection-column-8 {
    width: 150px !important;
    min-width: 150px !important;
}

.mainframe-rightsection-column-9 {
    width: 150px !important;
    min-width: 150px !important;
}

.mainframe-rightsection-column-10 {
    width: 12px !important;
    min-width: 12px !important;
}

.table-button {
    font-size: 10px;
    height: 20px;
    border-radius: 8px;
    border-color: #e5e5e5;
    background: #e5e5e5;
    color: #555555;
    cursor: pointer;
}