.mainFrameLeftSection {
    min-width: 220px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #D5D5D5;
    height: 100%;
}

.mainFrameLeftSectionTopLine {
    height: 30px;
    min-height: 30px;
    border-bottom: 1px solid transparent;
}

.mainFrameLeftSectionTop {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    border-bottom: 1px solid #D5D5D5;
}

.mainFrameLeftSectionTop .title {
    margin-top: 10px;
    align-self: center;
    color: #555555;
    font-size: 20px;
    font-weight: bold;
}

.addFolderButtonContainer {
    display: flex;
    gap: 2px;
}

.addFolderButton {
    height: 30px;
    width: 179px;
    display: flex;
    justify-content: center;
    gap: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.addFolderButton > span {
    margin-top: auto;
    margin-bottom: auto;
}

.addFolderIcon {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
}

.threeDotsContainer {
    height: 30px;
    width: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lighterGray);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.addFolderButtonNoText {
    background: var(--primary);
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 200px;
    min-width: 200px;
    max-height: 30px;
}

.mainFrameLeftSectionList {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}


.mainFrameLeftSectionList::-webkit-scrollbar {
    width: 8px;
    background: #f2f2f2;
    border-left: 1px solid #D5D5D5;
}

.mainFrameLeftSectionList::-webkit-scrollbar-thumb {
    color: #D5D5D5;
    background: #d5d5d5;
    border-radius: 3px;
    border-left: 2px solid rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0);
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

.searchContainer {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 6.5px;
}

.input-search {
    width: 100%;
    padding-right: 30px;
}

.clearSearchButton {
    position: absolute;
    right: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}