.modal-header {
  display: flex;
  justify-content: center;
  padding: 10px;
  background: #f4f4f4;
}

.modal-body {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.title {
  color: var(--gray);
  font-size: 16px;
  font-weight: bold;
}

.form-row {
  display: flex;
  padding-bottom: 15px;
}

.form-col {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 20px;
}

.label {
  font-size: 16px;
  color: var(--darkGray);
  display: flex;
  justify-content: space-between;
}

.form-left {
  min-width: 180px;
}

.form-right {
  min-width: 280px;
}

.form-right-detail {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  padding-left: 8px;
}

.required-asterisk {
  color: red;
  font-size: 16px;
  padding-right: 12px;
}

.no-padding-bottom {
  padding-bottom: 0;
}