.mainFrame {
  grid-column-start: 2;
  width: 100%;
  height: calc(100vh - 33px);
}

.mainFrameBody {
  grid-column-start: 2;
  display: flex;
  overflow: auto;
  height: calc(100vh - 104px);
}

.rightSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.rightSectionTop {
  display: flex;
  align-items: center;
  height: 50px;
  min-height: 50px;
  width: 100%;
  padding: 20px 0 0 20px;
}

.tableContainer {
  padding: 20px;
  height: 100%;
  width: 100%;
}

.button-primary {
  font-size: 12px;
  height: 26px;
  width: 180px;
  border-radius: 5px;
  margin-right: 20px;
}

.button-disabled {
  font-size: 12px;
  height: 26px;
  width: 60px;
  border-radius: 5px;
  border: 1px solid #d5d5d5;
}

.button-disabled-set {
  background-color: #fff;
  color: #666;
}

.button-disabled-unset {
  background-color: #f1f1f1;
}

.notification-container {
  display: flex;
  align-items: center;
  min-width: 340px;
  max-width: 340px;
}

.notification-span {
  width: 70px;
  text-align: right;
  font-size: 12px;
  color: #666;
}