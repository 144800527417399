.mainframe {
    padding-top: 74px;
    height: 100vh;
    grid-column-start: 2;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.mainframe-header {
    height: 40px;
    min-height: 40px;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
    display: flex;
    align-items: center;
}

.mainframe-body {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.mainframe-body-content {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
}

.mainframe-body-content button {
    min-width: 200px;
    width: 200px;
}

.mainframe-body-content input[type=checkbox] {
    margin-right: 20px;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
    margin-bottom: 10px;
}

.text {
    font-size: 14px;
    color: var(--gray);
}

.font-small {
    font-size: 12px;
}

.foooter {
    margin-top: auto;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.item-width {
    width: 670px;
    min-width: 670px;
}

.select-width {
    width: 450px;
    min-width: 450px;
}
