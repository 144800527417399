.mainframe-body-content * {
    box-sizing: border-box;
}

.mainframe-body-footer-buttons {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
}

.title {
    color: var(--gray);
    font-size: 16px;
    font-weight: bold;
}

.text {
    font-size: 14px;
    color: var(--gray);
}

.font-small {
    font-size: 12px;
}

.default-width {
    width: 50%;
    min-width: 230px;
}

.margin-left {
    margin-left: 60px;
}

.user-img {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
}

.modal-header {
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #f4f4f4;
    height: 50px;
}

.modal-body {
    padding: 20px 20px 10px 20px;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
}

.checkBox input {
  cursor: pointer;
}

.col-1 {
    flex: 1;
}

.col-2 {
    flex: 3;
}

.modal-footer{
    margin-top: auto;
    height: 50px;
}

.tabsContainer {
  height: 410px;
  max-height: 40vh;
  min-height: 10vh;
}

.modalTabContainer {
  height: 100%;
  padding: 15px;
  background-color: #f4f4f4;
  overflow-y: auto;
  box-sizing: border-box;
}

.modalTabItem {
  margin-bottom: 15px;
}

.modalTabItem:last-child {
  margin-bottom: 0;
}

.pt-15 {
    padding-top: 15px;
}
