.container {
    grid-column-start: 2;
}

.mainContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
