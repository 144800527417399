.mainFrame {
    height: 100vh;
    grid-column-start: 2;
}

.mainSections {
    display: flex;
    height: 100vh;
    padding-top: 74px;
    overflow: auto;
}

.resultSection {
    display: flex;
    height: 100vh;
    /*overflow: auto;*/
    overflow: hidden;

    /*background: red;*/
}

.attachDocSection {
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

}
