.mainframe {
    padding-top: 74px;
    grid-column-start: 2;
    height: 100vh;
}

.mainFrameBody {
  grid-column-start: 2;
  display: flex;
  overflow: auto;
  height: calc(100vh - 104px);
}

.mainframe-leftsection {
    width: 218px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--border-gray);
}

.mainframe-leftsection-top {
    display: flex;
    height: 130px;
    min-height: 130px;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid var(--border-gray);
    box-sizing: border-box;
}
.mainframe-leftsection-top button {
  cursor: pointer;
}

.mainframe-leftsection-list {
    height: 100%;
    overflow-y: scroll;
}


.mainframe-leftsection-list::-webkit-scrollbar {
    width: 8px;
    background: #f2f2f2;
    border-left: 1px solid #D5D5D5;
}

.mainframe-leftsection-list::-webkit-scrollbar-thumb {
    color: #D5D5D5;
    background: #d5d5d5;
    border-radius: 3px;
    border-left: 2px solid rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0);
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

.user-frame {
    border-bottom: 1px solid var(--border-gray);
    height: 26px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.user-frame-user-icon {
    padding: 0 10px;
}

.user-frame-user-icon img {
    padding-top: 4px;
    cursor: pointer;
}

.user-frame-username {
    font-size: 13px;
    color: #555555;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.mainframe-rightsection {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.home-text {
    font-size: 12px;
    color: #c1c1c1;
    margin-right: 10px;
}

.mainframe-rightsection-list {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px 40px;
    box-sizing: border-box;
}


.mainframe-rightsection-footer-buttons {
    border-top: 1px solid var(--border-gray);
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.mainframe-rightsection-footer-buttons button {
  cursor: pointer;
}


.mainframe-rightsection-mainsection {
    margin-bottom: 20px;
}

.mainframe-rightsection-mainsection-checkbox-line {
    font-size: 13px;
    color: #808080;
    display: flex;
    justify-content: space-between;
}

.groupLabel {
  width: 100%;
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mainframe-rightsection-mainsection-checkbox-line input {
    margin-left: 0;
    margin-bottom: 1px;
}

.mainframe-rightsection-mainsection-checkbox-line-leftsection {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.checkbox {
  cursor: pointer;
}

.mainframe-rightsection-mainsection-checkbox-line-rightsection {
    margin-right: 20px;
    display: flex;
}

.mainframe-rightsection-mainsection-checkbox-line-rightsection img {
    width: 15px;
    height: 13px;
    cursor: pointer;
}

.mainframe-rightsection-mainsection-checkbox-line-rightsection > div {
    margin: 0 2px;
}

.mainframe-rightsection-mainsection-checkbox-line-rightsection-num {
    position: relative;;
}

.mainframe-rightsection-mainsection-checkbox-line-rightsection-num > span {
    position: absolute;
    top: -1px;
}

.mainframe-rightsection-mainsection-content {
    width: 100%;
    min-height: 90px;
    border: 1px solid #808080;
    padding: 10px 0 30px 30px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-around;*/
}

.mainframe-rightsection-mainsection-content-userframe {
    width: 220px;
    max-width: 220px;
    height: 30px;
    font-size: 13px;
    color: #555555;
    border: 1px solid var(--border-gray);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 0 30px 10px 0;
    align-self: start;
}

.mainframe-rightsection-mainsection-content-userframe-leftsection {
    display: flex;
    align-items: center;
    max-width: 190px;
}

.dropItem-username-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mainframe-rightsection-mainsection-content-userframe-leftsection span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mainframe-rightsection-mainsection-content-userframe-leftsection img {
    width: 18px;
    margin: 0 10px;
    cursor: pointer;
}


.mainframe-rightsection-mainsection-content-userframe-rightsection {
    display: flex;
    align-items: center;
    /*color: #b2b2b2;*/
    /*margin-right: 10px;*/
}

.mainframe-rightsection-mainsection-content-userframe-rightsection img {
    cursor: pointer;
    width: 10px;
    margin: 0 10px;
}


.groupSearchInputContainer {
    padding-left: 0;
    padding-right: 0;
}

.groupSearchInput {
    font-family: 'FontAwesome', 'Noto Sans JP', 'Hiragino Sans', serif;
    font-size: 14px;
}

.groupSearchInputPosIcon {
    background-position-x: 0;
}

.group-name-editable-input {
    font-size: 13px;
    color: #808080;
}

.group-name-editable-input:focus {
    outline: none;
}