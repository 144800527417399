
.mainframe {
    grid-column-start: 2;
    height: 100vh;
    padding-top: 74px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.mainframe > header {
    min-height: 40px;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.mainframe-body {
    /*padding: 30px 120px 30px 60px;*/
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.mainframe-body > div:first-child {
    margin-top: 20px;
    display: flex;
}

.mainframe-body > div:first-child > div {
    margin-right: 80px;
}

.select-large {
    width: 500px;
}

.button-large {
    width: 200px;
}

.mainframe-body input[type=checkbox] {
    margin-right: 20px;
}

.baseInfo {
    display: flex;
    width: fit-content;
}

.baseInfoItem {
    margin-right: 80px;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
}

.text {
    font-size: 14px;
    color: var(--gray);
}

.mainframe > footer {
    padding: 10px;
    border-top: var(--defaultBorder);
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.templateSearchInput {
    flex: 1;
}