
.container {
    background-color: rgb(242, 242, 242);
    align-self: center;
    flex: 4;
    height: 46px;
    padding-left: 14px;
    padding-right: 26px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 31px;
}

.input {
    width: 95%;
    height: 40px;
    padding-left: 5px;
    margin-left: 15px;
    margin-right: 15px;
    background-color: transparent;
    border: none;
    font-size: 16px;
}



body {
    overflow: auto;
}

.filter-dropdown{
    /*height: 60px;*/
    width: 94%;
    position: absolute;
    top: 46px;
    right: 0px;
    display: flex;
    align-items: center;
    padding: 15px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 0 0 8px 8px;
    background: #FFFFFF;
}

.filter-button{
    width: 200px;
}

.filter-button:nth-child(2){
    margin-left: 10px;
}

.searchImg {
  width: 100%;
  height: 60%;
}

.searchBox {
  width: 55px;
  height: 46px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterButton {
  cursor: pointer;
}