.alertBox {
    display: none;
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.5);
}

.modalContainer {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.modal {
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000010;
    width: 482px;
    background: #FFFFFF;
    position: fixed;
    color: #555555;
    text-align: center;
}

.btn-alert {
    width: 110px !important;
}

.alertButtons {
    margin: 30px 0;
}

.alertButtons button + button {
    margin-left: 20px;
}

.alertMessage {
    padding: 50px 50px 0 50px;
    font-size: 14px;
    color: #555555;
    white-space: pre-line;
}

.textCenter {
    text-align: center;
}
