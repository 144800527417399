.bodyGrid {
    display: grid;
    grid-template-columns: 63px 1fr;
}

.borderBox {
    box-sizing: border-box;
}

.borderBox * {
    box-sizing: border-box;
}