.descTitle p {
    display: flex;
    justify-content: center;
}

.descBody p {
    margin-block-start: 0;
    margin-block-end: 0;
}

.descBody div {
    display: flex;
    justify-content: center;
}

.descBody ul {
    margin-block-start: 0;
    margin-block-end: 0;
    width: fit-content;
}


.descBody li {
    list-style-type: none;
    position: relative;
}

.descBody li::before {
    content: '.';
    position: absolute;
    left: -0.8em;
}

.firstParagraph {
  margin-top: 0;
}