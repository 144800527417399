
.modal {

    margin: auto auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 70%;
    width: 70%;
    max-width: 1030px;
    /*background: #fff;*/
}

.modalBody {
    overflow-y: auto;
    padding: 20px 40px 20px 40px;
    flex: 1;
    /*background: #6CAC55;*/
}

.modalBodyRow {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    align-content: center;
    align-items: center;
}


.modalBody label {
    font-size: 16px;
    color: var(--darkGray);
    width: 120px;
    max-width: 120px;
    min-width: 120px;
}

.tag-section {
    display: flex;
    align-items: center;
}

.tagText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    min-width: 90px;
}

.select {
    max-width: 170px;
    width: 170px;
    min-width: 170px;
}

.selectDisabled {
    max-width: 170px;
    width: 170px;
    min-width: 170px;
    background: #E5E5E5;
}

.dateInput {
    flex: 1;
}

.dateInput input {
    width: 100%;
}

.bigInput {
    width: 80%;
}

.ccControlNumberInput {
    width: 240px;
    -moz-appearance: textfield;
}

.ccControlNumberInput::-webkit-outer-spin-button,
.ccControlNumberInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.smallInput {
    width: 250px;
}

.button {
    max-width: 200px;
    width: 200px;
}

.buttonTrash {
    max-width: 40px;
    justify-self: end;
}

.radioRow {
  width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.modal footer {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-top: var(--defaultBorder);
    margin-top: auto;
    /*background: goldenrod;*/
}

.tagContainer {
    width: 100%;
    display: flex;
}

.tagFirstColumn {
    width: 180px;
}

.tagSecondColumn {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
}

.tagThirdColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
}


.dateDivisor{
    min-width: 20px;
    justify-self: center;
    align-self: center;
}

.divider {
    border-top: 1px solid #d5d5d5 !important;
    width: 100% !important;
    margin: 15px 0 15px 0 !important;
}