body {
    overflow: auto;
}

.mainFrame {
    padding-top: 74px;
    height: calc(100vh - 74px);
    grid-column-start: 2;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.mainFrameHeader {
    height: 40px;
    min-height: 40px;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
    display: flex;
    align-items: center;
}

.mainFrameBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.bodyContent {
    flex: 1;
    height: 100%;
    margin: 0 30px 0 30px;
    display: flex;
    flex-direction: column;
}

.bodyContentTop {
    margin: 30px 30px 0 30px;
}

.bodyContentMain {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 20px;
}

.bodyContentMainTable {
    min-height: 200px;
    height: 100%;
    margin-left: 70px;
    margin-right: 70px;
    margin-bottom: 15px;
}

.bodyContentMainBottom {
    margin-top: auto;
}

.bodyFooter {
    padding: 10px;
    border-top: var(--defaultBorder);
    display: flex;
    justify-content: space-between;
}

.text {
    font-size: 14px;
    color: var(--gray);
}

.ml70px {
    margin-left: 70px;
}

.mainFrame table {
    border-collapse: collapse;
    width: 100%;
}

.mainFrame table thead tr th {
    font-size: 12px;
    color: var(--darkGray);
    border: var(--defaultBorder);
    background: #F2F2F2;
    font-weight: unset;
    text-align: start;
    padding-left: 10px;
    height: 26px;
}

.mainFrame table tbody tr td {
    font-size: 12px;
    color: var(--darkGray);
    border: var(--defaultBorder);
    padding-left: 10px;
    height: 26px;
}

.w100px {
    width: 100px !important;
}

.w70px {
    width: 70px;
}

.mr80px {
    margin-right: 80px;
}

.divBox {
    height: 26px;
    font-size: 12px;
    color: #333333;
    border-right: var(--defaultBorder);
    border-top: var(--defaultBorder);
    border-bottom: var(--defaultBorder);
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    flex: 1;
}

.borderRight {
    border-right: var(--defaultBorder) !important;
}

.borderLeft {
    border-left: var(--defaultBorder) !important;
}

.downArrow {
    width: 12px;
    height: 18px;
    color: var(--gray);
}

.bkLight {
    background: #f4f4f4;
}

.selectTable {
    border-top: var(--defaultBorder) !important;
    border-right: var(--defaultBorder) !important;
    border-bottom: 0 ;
    border-left: 0;
    font-size: 12px !important;
    height: 26px !important;
    padding-left: 10px !important;
    box-sizing: border-box !important;
    color: #333333 !important;
    background-color: #f2f2f2 !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-image: url("../../../public/images/Dropdown-box-arrow.svg") !important;
    background-repeat: no-repeat !important;
    background-position-x: 96% !important;
    background-position-y: center !important;
    border-radius: 0 !important;
    flex: 1;
    margin: 0;
}

.topSection {
  display: flex;
  justify-content: space-between;
  margin-right: 70px;
}

.documentTypeText {
    font-size: 14px;
    color: var(--danger) !important;
    margin-left: 30%;
}

.documentTypeText > div:nth-child(2) > span:nth-child(1) {
    margin-right: 38px;
    margin-left: 6px;
}

.documentTypeText > div:nth-child(1) > span:nth-child(1) {
    margin-right: 8px;
    margin-left: 6px;
}
