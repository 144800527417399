.descDesc {
    text-align: center;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formItem {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 30px;
    align-items: center;
    margin-top: 10px;
}

.formItemField {
    min-width: 60px
}

.errorText{
    color: rgb(229, 0, 25);
}

.firstParagraph {
  margin-top: 0;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
}

.buttonContainer button {
  margin: 0 10px;
  min-width: 220px;
}