.sideBar{
    position: fixed;
    top: 0;
    left: 0;
    background-color: #54C3F1;
    width: 63px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.item {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 1px 0;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

.itemSelected {
    border-radius: 50%;
    background-color: var(--red);
}

.item:hover > .itemOnHover {
    visibility: visible;
    opacity: 1;
    /*animation-duration: 0.5s;*/
    /*animation-name: fade-in;*/
}

.itemOnHover {
    display: flex;
    visibility: hidden;
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 200px;
    background-color: #e50019;
    height: 40px;
    border-radius: 20px 20px 20px 20px;
    align-items: center;
    color: #ffffff;
    font-size: 0.8rem;
    pointer-events:none;
    transition: 0.200s;
}

.itemOnHover > span {
    margin-left: 40px;
}

.itemImg {
    z-index: 1;
}

.itemImgSelected{
    border-radius: 50%;
    background-color: var(--red);
}
