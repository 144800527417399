
.body {
    height: 100%;
    overflow: auto;
    padding: 20px;
    font-size: 14px;
      color: #555555;
  }

  /*Dropdowns divs */

  .body button {
    height: 20px;
    width: 39px !important;
    background: #bae3f9 !important;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px !important;
  }


.deleteImg {
    height: 14px;
    width: 14px;
  }

  .div-table {
    width: 320px;
    height: 25px !important;
    line-height: 25px;
    font-size: 12px;
    color: #333333;
    background-color: #f2f2f2 !important;
    padding-left: 10px;
    border: var(--defaultBorder);
    border-bottom: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .div-table-last {
    width: 130px !important;
    border-left: 0;
    height: 25px;
  }

  .select-table {
    border-top: var(--defaultBorder);
    border-right: var(--defaultBorder);
    border-bottom: 0;
    border-left: 0;
    font-size: 12px !important;
    color: #333333 !important;
    min-width: 130px;
    max-width: 130px;
    height: 25px !important;
    padding-left: 10px !important;
    background-color: #ffffff !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-image: url("../../../public/images/Dropdown-box-arrow.svg") !important;
    background-repeat: no-repeat !important;
    background-position-x: 96% !important;
    background-position-y: center !important;
    border-radius: 0 !important;
    flex: 1;
    margin: 0;
  }

  .select-table-disabled {
    border-top: var(--defaultBorder);
    border-right: var(--defaultBorder);
    border-bottom: 0;
    border-left: 0;
    font-size: 12px !important;
    color: #333333 !important;
    min-width: 130px;
    max-width: 130px;
    height: 25px !important;
    padding-left: 10px !important;
    background-color: #ffffff !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-repeat: no-repeat !important;
    background-position-x: 96% !important;
    background-position-y: center !important;
    border-radius: 0 !important;
    flex: 1;
    margin: 0;
  }

  .select-table-last {
    min-width: 320px !important;
    max-width: 320px !important;
    border-left: var(--defaultBorder) !important;
  }

  .background-gray {
    background-color: #f2f2f2 !important;
  }

  .bodyMain {
    display: flex;
  }

  .bodyMain > div:first-child {
    min-width: 500px;
  }
