.container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: 74px;
    grid-column-start: 2;
    height: 100vh;
}

.containerBody {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 165px);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.header {
    height: 40px;
    min-height: 40px;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
    width: 200px;
}

.footer {
    padding: 10px;
    height: fit-content;
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.footers {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
