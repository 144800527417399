
.selectedFolderFrame {
    border-bottom: 1px solid #d5d5d5;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    flex-direction: row;
    background: #BAE3F9;
}

.folderFrame {
    border-bottom: 1px solid #d5d5d5;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}


.folderOptionsLeftFrame {
    padding: 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 85px;
}

.folderOptionsLeftFrameText {
    font-size: 10px;
    color: #777777;
    flex: 1;
    display: flex;
    justify-content: space-evenly;
}

.folderOptionsLeftFrameTextEnabled {
    color: rgb(119, 119, 119);
}

.folderOptionsLeftFrameTextGrayedOut {
    color: rgb(193, 193, 193)
}

.folderOptionsLeftFrameIcon {
    flex: 1;
    align-self: center;
}

.folderOptionsLeftFrameIcon img {
    height: 16px;
}

.selectedFolderOptionsLeftFrameText {
    font-size: 10px;
    color: #222222;
    flex: 1;
    display: flex;
    justify-content: space-evenly;
}

.selectedFolderOptionsRightFrameText {
    font-size: 12px;
    color: #222222;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.extraIcon {
    width: 10px;
    cursor: pointer;
}

.folderOptionsRightFrame {
    margin: 0 5px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.folderOptionsRightFrameText {
    font-size: 12px;
    color: #777777;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.pinnedIcon {
    width: 12px;
}

