
.mainFrame {
  padding-top: 74px;
  grid-column-start: 2;
  overflow: auto;
}

.mainFrameBody {
  grid-column-start: 2;
  display: flex;
  overflow: auto;
  height: calc(100vh - 104px);
}

.mainframe button {
    cursor: pointer;
}

.mainframe-leftsection {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #D5D5D5;
}

.mainframe-leftsection > header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
}

.mainframe-leftsection > div {
    margin: 20px 10px 0 10px;
    display: flex;
    width: 360px;
}

.date-field > input:last-child {
    width: 60% !important;
    margin-left: 10px;
}

.date-field > label {
    padding-left: 20px;
    font-size: 14px !important;
}

.mainframe-leftsection label {
    font-size: 16px;
    color: var(--darkGray);
    width: 120px;
    min-width: 120px;
    margin-right: 20px;
}

.mainframe-leftsection > footer {
    margin-top: auto;
    height: 50px;
    display: flex;
    justify-content: space-between;
    border-top: var(--defaultBorder);
}

.mainframe-rightsection {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.mainframe-rightsection-footer {
    min-height: 50px;
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.top-button {
    height: 30px;
    width: 198px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1px;
}

.w-100 {
    width: 100% !important;
}

.footer-button, .downloadButton {
  cursor: pointer;
}