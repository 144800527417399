.bodySection {
    height: 100%;
    padding: 30px 0 30px 30px;
    overflow-y: hidden;
}

.bodySectionMain {
    height: 100%;
    overflow-y: auto;
    margin-top: 10px;
}

.bodySection .topButtonsImg {
    width: 30px;
    height: 30px;
    margin-right: 45px;
    cursor: pointer;
}

.imagesContainerSection {
    margin-top: 10px;
    width: calc(100% - 30px) !important;
    height: 300px;
}

.arrowImg {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 60px;
    top: 10px;
    cursor: pointer;
}

.pdfContainer {
    position: relative;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
    min-width: 120px;
}

.text {
    font-size: 14px;
    color: var(--gray);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tagTextContent {
    width: calc(100% - 140px);
    padding-right: 30px
}


.bodySection ::-webkit-scrollbar-thumb {
    color: #D5D5D5;
    background: #d5d5d5;
    border-radius: 6px;
    border-left: 3px solid rgba(0, 0, 0, 0);
    border-right: 2px solid rgba(0, 0, 0, 0);
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

.bodySection ::-webkit-scrollbar {
    width: 17px;
    border-radius: 4px;
    background: var(--white);
    border-left: 1px solid #D5D5D5;
}