.chip {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.chipIcon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.chipLabelSide {
  min-width: 40%;
  max-width: 40%;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.chipLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.chipValueSide {
  min-width: 60%;
  max-width: 60%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.chipValue {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.px {
  padding-left: 4px;
}