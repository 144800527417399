.detail-row-container {
    background: var(--primaryLight);
}

.detail-row {
    height: 40px;
    background: var(--primaryLight);
    display: flex;
    align-items: center;
    border: var(--defaultBorder);
    padding-left: 50px;
}

.detail-row label {
    font-size: 14px;
    margin-left: 30px;
    color: #181d1f;
}

.detail-row span {
    font-size: 14px;
    color: #181d1f;
}

.detail-row > div:nth-child(1) {
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.detail-row > div:nth-child(2) {
    width: 230px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.detail-row > div:nth-child(3) {
    width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.detail-row > div:nth-child(4) {
    width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tag {
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
