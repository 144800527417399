.paginationButtonsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    position: relative;
    margin-bottom: 3px;
    margin-right: 40px;
}

.paginationButtonsContainer > .pageCounter{
    display: flex;
    position: absolute;
    right: 90px;
}

.paginationButtonsContainer > .pageCounterFirst{
    display: flex;
    position: absolute;
    right: 200px;
}

.paginationButtonsContainer > .pageCounterLast{
    display: flex;
    position: absolute;
    right: 40px;
}

.paginationPagesContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
    margin-right: 5%;
    margin-top: 5px;
}

.image {
    max-width: 20px;
    max-height: 20px;
    cursor: pointer;
    font-weight: bold;
}

.imageDisabled {
    opacity: .4;
    cursor: default;
}
