.form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formDesc {
    text-align: center;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.formAlert {
    height: 30px;
}

.formItem {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 30px;
    align-items: center;
    margin-top: 10px;
}

.formIteField {
    min-width: 60px
}

.errorText{
    color: rgb(229, 0, 25);
}

.firstParagraph {
  margin-top: 0;
}