.alertBox {
    display: none;
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.5);
}

.modalContainer {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.modal1 {
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000010;
    background: #FFFFFF;
    position: fixed;
    color: #555555;
}
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    background-color: white;
}
