
.mainframe {
    grid-column-start: 2;
    height: 100vh;
    padding-top: 74px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.mainframe-body > header {
    min-height: 40px;
    width: 100%;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.mainframe-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.mainframe > footer {
    padding: 10px;
    display: flex;
    border-top: none;
    justify-content: space-between;
}

.select {
  max-width: 170px;
  width: 170px;
  min-width: 170px;
}

.mainCard {
  display: flex;
  min-height: 248px;
  height: 100%;
}

.table-container {
  flex: 3;
  min-height: 248px;
  height: 100%;
}

.sideCard {
  border-top: var(--defaultBorder);
  border-bottom: var(--defaultBorder);
  flex: 1;
  min-height: 248px;
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  border-left: var(--defaultBorder);
}

.sideCardLabel {
  margin-bottom: 12px;
}

.label-container {
    display: flex;
    justify-content: space-between;
    min-width: 180px;
    width: 180px;
}

.label-container span {
  color: red;
  font-size: 16px;
  padding-right: 12px;
}

.upperCard {
    margin-top: auto;
    font-size: 14px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  height: 30px;
}

.column {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column:last-child {
  margin-right: 0px;
}

.firstColumn {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}

.secondColumn { 
  width: 1000px;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.secondColumnContent {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fourthColumn {
  width: 400px;
  max-width: 400px;
  min-width: 400px;
}

.labelColumn {
  padding-left: 10px;
}

.tagText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90px;
  min-width: 90px;
}

.dateDivider {
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableWrapper {
  height: calc(100% - 42px);
}

.paginationLimitContainer {
  border-bottom: var(--defaultBorder);
  height: 42px;
  padding: 0 20px;
  font-size: 14px;
  margin-right: -40px;
}

.paginationLimitWarning {
  color: red;
  margin-left: 40px;
}

.tagHeaderOnForm {
  color: var(--tag-form-color);
}

:root {
  --tag-form-background: rgba(33, 150, 243, 0.1);
  --tag-form-color: #1733a4;
}

.alertMessage {
    padding: 30px 50px 0 50px;
    font-size: 14px;
    color: #555555;
    text-align: center;
}

.divider {
    border-top: 1px solid #d5d5d5 !important;
    margin: 5px 20px 15px 20px !important;
}

.documentsInfo {
    display: flex;
    padding: 0px 30px 25px 30px;
    font-size: 14px;
    color: #555555;
}