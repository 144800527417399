.AutoCompleteInputStyle{
  width: 100%;
  height: 30px;
  border: 1px solid #D5D5D5;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 14px;
  color: #555555;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  background-image: url("../../../../../public/images/Dropdown-box-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: center;
}
