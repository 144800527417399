.card {
    margin: 40px;
    width: 346px;
    height: 213px;
    background-color: #F2F2F2;
    text-align: center;
    border-radius: 3px;
    position: relative;
}

.cardBody {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.cardText {
    font-weight: 600;
    font-size: 16px;
    color: #555555;
}

.cardFooter {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 100px 10px 10px 10px;
    display: flex;
    justify-content: flex-end;
}

.cardImg {
    width: 27px;
    height: 42px;
}

.cardImgLg {
    width: 43px;
    height: 36px;
    opacity: 0.8;
}
