
.inputContainer {
    padding-left: 5px;
}

.input {
    height: 30px;
    border: none !important;
    width: 0;
    min-width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    box-sizing: border-box;
}

.input:focus {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
