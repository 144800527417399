

.box {
    min-height: 30px;
    height: 30px;
    border-bottom: 1px solid #D5D5D5;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.item {
  cursor: pointer;
  margin-right: 8px;
  font-size: 12px;
  color: #c1c1c1;
}

.item:hover {
  color: #000;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  color: #54c3f1;
  margin-right: 8px;
  cursor: default;
  font-size: 12px;
}

.last-item {
  font-size: 12px;
  margin-right: 8px;
  cursor: default;
}