body {
    overflow: auto;
}

.mainFrame {
    padding-top: 74px;
    height: calc(100vh - 74px);
    grid-column-start: 2;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.mainFrameHeader {
    height: 40px;
    min-height: 40px;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
    display: flex;
    align-items: center;
}

.mainFrameBody {
    /*flex: 1;*/
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.bodyContentTop {
    flex: 1;
    height: 100%;
    margin: 20px;
    display: flex;
}

.bodyContentTopLeft {
    flex: 3;
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.bodyContentMain {
    display: flex;
    height: 100%;
    margin: 0 20px 20px 20px;
}

.bodyContentMainLeft {
    flex: 3;
}

.bodyFooter {
    padding: 10px;
    border-top: var(--defaultBorder);
    display: flex;
    justify-content: space-between;
}

.mainFrame table {
    border-collapse: collapse;
    width: 100%;
}

.mainFrame table thead tr th {
    font-size: 12px;
    color: var(--darkGray);
    border: var(--defaultBorder);
    background: #F2F2F2;
    font-weight: unset;
    text-align: start;
    padding-left: 10px;
    height: 26px;
}

.mainFrame table tbody tr td {
    font-size: 12px;
    color: var(--darkGray);
    border: var(--defaultBorder);
    padding-left: 10px;
    height: 26px;
}

.tableButton {
    font-size: 12px;
    height: 26px;
    width: 90px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.tableButtonDisabled {
    font-size: 12px;
    height: 26px;
    width: 90px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: default;
    color: white;
}

.customSearchtHeader {
    margin: 20px;
}

.importButtonContainer {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
}

.refreshContainer {
    cursor: pointer;
    margin-right: 5px;
}