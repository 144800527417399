.container {
    position: relative;
    box-sizing: border-box;
    color: var(--black);
    max-height: 100%;
}

.menu {
    position: absolute;
    background-color: var(--white);
    min-width: 200px;
    top: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 30vh;
    overflow-y: auto;
}

.center {
    left: 50%;
    transform: translateX(-50%);
}

.left {
    right: 0;
    left: initial;
    transform: none;
}

.right {
    left: 0;
    right: initial;
    transform: none;
}

.item {
    box-sizing: border-box;
    font-size: 14px;
    min-width: 100%;
    padding: 8px 16px;
    cursor: pointer;
    text-align: left;
    min-height: 40px;
    max-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.item:hover {background-color: var(--lightGray);}

.disabled {
    background: #F5F5F5;
}