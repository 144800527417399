.mainFrame {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.mainContent {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.title {
    font-size: 28px;
    font-weight: bold;
    color: #444444;
    margin: 0;
    display: flex;
    justify-content: center;
}

.desc {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #555555;
    font-size: 14px;
}

.body {
    display: flex;
    justify-content: center;
    align-items: center;
}
