.cell {
    overflow: initial;
}

.ag-theme-alpine, .ag-theme-alpine-dark {
    --ag-header-background-color: #f2f2f2;
    --ag-odd-row-background-color: white;
}

.ag-cell-focus,.ag-cell-no-focus{
    border:none !important;
}
/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus{
    border:none !important;
    outline: none;
}

.ag-cell-inline-editing {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent;
}

.downloadButtonContainer {
    min-height: 50px;
    padding:10px;
    border-bottom: 1px solid #D5D5D5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ag-side-buttons {
  padding-top: 0 !important;
  width: 20px !important;
}

.ag-side-button {
  background-color: #54C3F1;
  height: 43px !important;
  width: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-side-button button {
  min-height: 100% !important;
  border: 0 !important;
  padding: 0 !important;
}

.ag-icon-filter {
  color: white;
  font-size: 20px;
}

.ag-overlay-no-rows-wrapper {
  white-space: pre-line;
}

.ag-theme-alpine .add-border-right.ag-cell-focus,
.ag-theme-alpine .add-border-right.ag-cell-no-focus {
    border-right: 1px solid #BABFC7 !important;
}