.modal-header {
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #f4f4f4;
}

.modal-body {
    padding: 20px;
    height: 100%;
    display: flex;
}

.col-1 {
    flex: 1;
}

.col-2 {
    flex: 3;
}

.col-2 div:nth-child(4) {
    position: relative;
}

.col-2 div:nth-child(4) > div {
    position: absolute;
    left: 230px
}

.modal-footer{
    margin-top: auto;
}

.user-img {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
}

.mainframe-body-footer-buttons {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}


.margin-left {
    margin-left: 60px;
}

.default-width {
  width: 50%;
  min-width: 230px;
}

.text {
    font-size: 14px;
    color: var(--gray);
}

.title {
    color: var(--gray);
    font-size: 18px;
    font-weight: bold;
}


.label {
    font-size: 16px;
    color: var(--darkGray);
    margin: 15px 0 0 0;
}

