#parentframe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #54C3F1;
    height: 100vh;
}
.mainframe {
    margin: 0 auto;
    padding-top: calc(50vh - 200px);
    width: 366px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #54C3F1;
}
.inputsframe {
    width: 350px;
}
.inputframe {
    height: 39px;
    display: flex;
}
.inputframe input {
    flex: 1;
    background-color: rgb(213, 213, 213);
    border: 5px solid white;
    border-radius: 8px;
    font-size: 16px;
    padding-left: 10px;
}
::placeholder {
    color: rgb(119, 119, 119);
}
.textaboveinputframe {
    font-size: 14px;
    color: rgb(51, 51, 51);
    margin-top: 10px;
    margin-bottom: 3px;
}
.forgotpasswordtext {
    color: white;
    font-size: 12px;
    text-align: right;
    margin-bottom: 30px;
}
.forgotpasswordlink{
    text-decoration: none;
    color: #ffffff;
 }
.loginbuttonframe {
    margin-top: 20px;
    width: 350px;
    height: 39px;
    display: flex;
}
.loginlinkframe {
    margin-top: 60px;
    width: 350px;
    height: 39px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.loginlinkframe a {
    color: white;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
}
.loginbutton {
    flex: 1;
    background-color: #e60012;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
}

.sso-button {
    flex: 1;
    background-color: white;
    color: var(--darkGray);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
}
.loginbutton[disabled] {
    background-color: rgb(119, 119, 119);
}

.image{
    width: 100%;
}

.errorText{
    color: rgb(229, 0, 25);
    white-space: pre-line;
    text-align: center;
    margin: 0 -75px;
}

.appVersion {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  color: white;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.buttonsdivider {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 !important;
    border: 1px solid rgb(147, 213, 241) !important;
    width: 470px !important;
    margin-left: -61px;
}