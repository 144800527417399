.modal-header {
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #f4f4f4;
}

.headerTitle {
    color: var(--gray);
    font-size: 18px;
    font-weight: bold;
}

.modal-body {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.modal-footer{
    margin-top: auto;
}

.mainframe-body-footer-buttons {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}