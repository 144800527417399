.container {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    font-family: 'FontAwesome', 'Noto Sans JP', 'Hiragino Sans', serif;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    font-size: 14px;
    color: #D5D5D5;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: var(--white);
}

.inputPosIcon {
    width: 25px;
    height: 30px;
    background-image: url("../../../public/images/Dropdown-box-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    cursor: pointer;
    border-radius: 0 3px 3px 0;
    border-top: 1px solid #D5D5D5;
    border-bottom: 1px solid #D5D5D5;
}

.inputContainer {
    flex: 1;
    padding-left: 5px;
}

.inputWrapper {
  max-height: 30px;
  display: flex;
  align-items: center;
}

.input {
    height: 30px;
    border: none;
    width: 0;
    min-width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;
    background: transparent;
    font-size: 14px;
    margin: auto;
}

.input:focus {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.disabled {
    background: #F5F5F5;
}