.item {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid var(--border-gray);
    padding: 0 10px 10px 10px;
    margin-bottom: 30px;
    border-radius: 5px;
}

.item:last-child {
    margin-bottom: 0;
}

.text {
    height: 30px;
    font-size: 14px;
    color: var(--gray);
    display: flex;
    align-items: center;
    min-width: 80px;
}

.inputLabel {
    height: 30px;
    font-size: 14px;
    color: var(--gray);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    min-width: 80px;
}

.textValue {
    height: 30px;
    font-size: 14px;
    color: var(--gray);
    display: flex;
    align-items: center;
    min-width: 105px;
    max-width: 105px;
}

.smallerLabel {
    height: 32px;
    font-size: 12px;
    color: var(--midGray);
    min-width: 50px;
    display: flex;
    align-items: center;
}

.textLight {
  font-size: 14px;
  color: var(--midGray);
}

.rightContainer {
  flex: 1;
  height: 100%;
  padding-top: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.leftContainer {
  padding-right: 20px;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  height: 100%;
  flex: 1;
}

.selectContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Button styles */

.itemButton {
  width: 100px;
}

.itemLargeButton {
  width: 200px;
}

.buttonContainer {
  min-height: 100%;
  padding: 0 0 0 20px;
  min-width: 240px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.buttonRow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.buttonImage {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.disabledButton {
  cursor: default;
}


.refreshContainer {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.refreshButton {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.contractLeftSection {
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;
}

.contractUpperRow {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contractLowerRow {
  padding-top: 20px;
  padding-left: 50px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.keysContainer {
  width: 100%;
  min-height: 52px;
  padding-bottom: 22px;
  display: flex;
  flex-direction: column;
  min-width: 450px;
}

.importButtonContainer {
  display: flex;
  justify-content: flex-end;
  min-width: 250px;
  margin-right: 20px;
}

.paddingRight {
  padding-right: 20px;
}

.marginRight {
  margin-right: 20px;
}