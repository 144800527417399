
.mainframe-body-footer-buttons {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
}

.title {
    color: var(--gray);
    font-size: 16px;
    font-weight: bold;
}

.text {
    font-size: 14px;
    color: var(--gray);
}

.font-small {
    font-size: 12px;
}

.default-width {
  width: 50%;
  min-width: 230px;
}

.margin-left {
    margin-left: 60px;
}

.user-img {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
}

.modal-header {
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #f4f4f4;
}

.modal-body {
    padding: 20px;
    height: 100%;
    display: flex;
}

.col-1 {
    flex: 1;
}

.col-2 {
    flex: 3;
}

.modal-foooter{
    margin-top: auto;
}

.tabsContainer {
  height: 350px;
}

.modalTabContainer {
  height: 100%;
  padding: 15px;
  background-color: #f4f4f4;
  overflow-y: auto;
  box-sizing: border-box;
}

.modalTabItem {
  margin-bottom: 15px;
}

.modalTabItem:last-child {
  margin-bottom: 0;
}
