.bodyBackground {
    /*display: none;*/
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
}

.modalContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.modal {
    width: 850px;
    margin-top: 155px;
    margin-right: 20px;
    /* margin-bottom: 71px; */
    border: 1px solid var(--border-gray);
    background: #f4f4f4;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000029;
    /*position: fixed;*/
}

.bodyBackground ::-webkit-scrollbar-thumb {
    color: #D5D5D5;
    background: #d5d5d5;
    border-radius: 6px;
    border-left: 3px solid rgba(0, 0, 0, 0);
    border-right: 2px solid rgba(0, 0, 0, 0);
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

.bodyBackground ::-webkit-scrollbar {
    width: 17px;
    border-radius: 4px;
    background: var(--white);
    border-left: 1px solid #D5D5D5;
}
