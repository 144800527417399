
.container form {
    display: flex;
    flex-direction: column
}

.container button {
    cursor: pointer;
}

.selection {
    min-width: 372px;
}

.container label {
    font-size: 18px;
}

.container li {
    list-style-type: none;
    position: relative;
}

.container li::before {
    content: '.';
    position: absolute;
    left: -0.8em;
    top: -0.4em;
}

.container button + button {
    margin-left: 50px;
}

.container {
    margin: 0 auto;
    padding-top: calc(50vh - 200px);
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 28px;
    font-weight: bold;
    color: #444444;
    margin-bottom: 0;
}

.sub-title {
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 100;
}

.text-style {
    color: #808080;
}

.flex {
    display: flex;
}

.drop-down-menu-label {
    width: 30%;
    margin-right: 1.3rem;
}