#parentframe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    height: 100vh;
}
.mainframe {
    margin: 0 auto;
    padding-top: calc(50vh - 200px);
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.inputsframe {
    width: 350px;
}
.inputframe {
    height: 39px;
    display: flex;
}
.inputframe input {
    flex: 1;
    background-color: rgb(213, 213, 213);
    border: 5px solid white;
    border-radius: 8px;
    font-size: 16px;
    padding-left: 10px;
}
::placeholder {
    color: rgb(119, 119, 119);
}
.textaboveinputframe {
    font-size: 14px;
    color: rgb(51, 51, 51);
    margin-top: 10px;
    margin-bottom: 3px;
}
.forgotpasswordtext {
    color: white;
    font-size: 12px;
    text-align: right;
    margin-bottom: 30px;
}
.forgotpasswordlink{
    text-decoration: none;
    color: #ffffff;
 }
.loginbuttonframe {
    margin-top: 20px;
    width: 350px;
    height: 39px;
    display: flex;
}
.loginbutton {
    flex: 1;
    background-color: #e60012;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
}

.loginbutton[disabled] {
    background-color: rgb(119, 119, 119);
}

.image{
    width: 100%;
}

.errorText{
    font-size: 16px;
    text-align: center;
    line-break: auto;
    white-space: pre;
    color: #555555;
}

.button {
    width: 366px;
    background-color: white;
    color: var(--darkGray);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    height: 35px;

    margin-top: 1.5rem;
}

.appVersion {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  color: white;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
}