.alert {
    color: var(--red);;
}

.clipboard {
    height: 20px;
    cursor: pointer;
}

.api-key-alert-title {
    justify-content: center;
    column-gap: 5px;
}

.api-key-alert-content {
    text-align: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  min-width: 450px;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 50px;
  padding-bottom: 22px;
}

.textTitleContainer {
  display: flex;
  height: 30px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.leftButtonContainer {
  padding-left: 50px;
}

.updateKeyContainer {
  padding-left: 20px;
}