.bodyBackground {
    /*display: none;*/
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modalContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.modal {
    height: calc(100% - 175px);
    margin-right: 25px !important;
    width: 850px;
    margin-top: 155px;
    border: 1px solid var(--border-gray);
    background: #f4f4f4;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000029;
    /*position: fixed;*/
}

.headerSection {
    width: 100%;
    height: 44px;
}

.headerSection .leftCol {
    font-size: 16px;
    color: var(--darkGray);
    display: flex;
    align-items: center;
}

.headerSection .rightCol {
    font-size: 16px;
    color: #777777;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.currentHeaderSection {
    background: #d5d5d5;
}

.bodySection {
    height: calc(100% - 44px);
    margin: 35px 0 0 35px;
    overflow-y: auto;
}

.bodySection .topButtonsImg {
    width: 30px;
    height: 30px;
    margin-right: 30px;
}

.imagesContainerSection {
    width: calc(100% - 20px);
    height: 300px;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000029;
    background: #EFEFEF;
    padding: 20px 110px 0 150px;
    box-sizing: border-box;
    overflow-y: auto;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-right: 20px;
    position: relative;
}

.imagesContainerSection img {
    width: 154px;
    height: 200px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.imagesContainerSection .arrowImg {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -10px;
    top: 10px;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
    width: 120px;
}

.text {
    font-size: 14px;
    color: var(--gray);
}

.tagTextContent {
    width: calc(100% - 140px);
}


.bodyBackground ::-webkit-scrollbar-thumb {
    color: #D5D5D5;
    background: #d5d5d5;
    border-radius: 6px;
    border-left: 3px solid rgba(0, 0, 0, 0);
    border-right: 2px solid rgba(0, 0, 0, 0);
    border-top: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
}

.bodyBackground ::-webkit-scrollbar {
    width: 17px;
    border-radius: 4px;
    background: var(--white);
    border-left: 1px solid #D5D5D5;
}
