.modal-header {
  display: flex;
  justify-content: center;
  padding: 10px;
  background: #f4f4f4;
}

.modal-body {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  font-size: 14px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.title {
  color: var(--gray);
  font-size: 16px;
  font-weight: bold;
}

.form-row {
  display: flex;
  padding-bottom: 20px;
}

.form-col {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 20px;
}

.label-container {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 16px;
  color: var(--darkGray);
}

.form-left {
  width: 180px;
  display: flex;
  justify-content: space-between;
}

.form-left span {
  color: red;
  font-size: 16px;
  padding-right: 12px;
}

.input {
  width: 280px;
}

.checkbox {
  margin: 4px 10px 0 0;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.no-padding-bottom {
  padding-bottom: 0;
}