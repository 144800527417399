
.container{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}


.headerSection {
    display: flex;
    height: 44px;
    min-height: 44px;
}

.headerSection .headerCol {
    font-size: 16px;
    color: var(--darkGray);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.headerSection .headerColSelected {
    background: #d5d5d5;
}

.tabItem {
  height: 100%;
  width: 100%;
}

