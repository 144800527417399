.mainframe {
  grid-column-start: 2;
  height: 100vh;
  padding-top: 74px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.mainHeader {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #D5D5D5;
}

.headerInfoBox {
  padding: 20px;
  flex: 1;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.infoTextBox {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.infoTextBox:last-child {
  margin-bottom: 0;
}

.infoTitle {
  min-width: 130px;
  font-size: 16px;
  color: var(--darkGray);
}

.infoText {
  font-size: 16px;
  color: var(--darkGray);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.selectContainer {
  padding: 20px;
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.selectBox {
  width: 330px;
  height: 65px;
}

.label {
  font-size: 16px;
  color: var(--darkGray);
  width: 250px;
  margin-bottom: 10px;
}

.table-container {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.table-box {
  width: 100%;
  flex: 1;
  overflow: auto;
}

.table-header {
  display: flex;
  min-height: 95px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 20px;
}

.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.table-button {
  font-size: 10px;
  height: 22px;
  width: 36px;
  border-radius: 8px;
  border-color: #e5e5e5;
  background: #e5e5e5;
  color: #555555;
  cursor: pointer;
}

.table-input {
  width: 100%;
  height: 30px;
  border: 1px solid #D5D5D5;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 14px;
  color: #555555;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  background-repeat: no-repeat;
}