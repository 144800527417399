@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}
.spin {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px var(--lightGray);
    border-bottom-color: var(--red);
    border-radius: 50%;
    content: "";
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 80px;
    max-height: 80px;
}
