.selectTable {
    border-top: var(--defaultBorder) !important;
    border-right: var(--defaultBorder) !important;
    border-bottom: var(--defaultBorder) !important;
    border-left: 0;
    font-size: 12px !important;
    height: 26px !important;
    padding-left: 10px !important;
    box-sizing: border-box !important;
    color: #333333 !important;
    background-color: #f2f2f2 !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-image: url("../../../public/images/Dropdown-box-arrow.svg") !important;
    background-repeat: no-repeat !important;
    background-position-x: 96% !important;
    background-position-y: center !important;
    border-radius: 0 !important;
    flex: 1;
    margin: 0;
}

.borderLeft {
    border-left: var(--defaultBorder) !important;
}

.divBox {
    height: 26px;
    font-size: 12px;
    color: #333333;
    border-right: var(--defaultBorder);
    border-top: var(--defaultBorder);
    border-bottom: var(--defaultBorder);
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    flex: 1;
}
