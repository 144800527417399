.tagBulkRow {
  background-color: #e5e5e5 !important;
  color: #f8f8f8 !important;
}

.tagBulkRow.ag-row-selected::before {
  background-color: white !important;
}
.tagBulkRow.ag-row-selected {
  color: black !important;
}

.tagBulkRow .tagCellOnForm {
  background-color: #e5e5e5 !important;
  color: #f8f8f8 !important;
}

.tagBulkRow.ag-row-selected .tagCellOnForm {
  background-color: var(--tag-form-background) !important;
  color: var(--tag-form-color) !important;
}

.tagBulkRow.ag-row-hover .tagCellOnForm {
  background-color: transparent !important;
}