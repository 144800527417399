.radio-group input{
  cursor: pointer;
}


.radio-group > *:first-child {
    margin-left: 0px;
    margin-right: 0.5em
}

.radio-group > *:not(:first-child) {
    margin-left: 3em;
    margin-right: 0.5em
}
