.mainframe {
  height: 100vh;
  padding-top: 74px;
  grid-column-start: 2;
  display: flex;
  flex-direction: column;
}

.mainFrameBody {
  flex: 1;
  width: calc(100vw - 63px);
  overflow: auto;
}

.body {
  min-width: fit-content;
  height: 100%;
}

.tabTitle {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-wrap: nowrap;
}

.tabTitleBadge {
  background-color: var(--red);
  color: var(--white);
  transform: initial !important;
  position: initial !important;
}