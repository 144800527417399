.button {
    width: 280px;
    height: 45px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.solid {
    color: #fff;
    border-color: transparent;
}

.solid[disabled] {
    background-color: var(--lighterGray);
}

.outline {
    border-style: solid;
}
.outline[disabled] {
    color: var(--lighterGray);
    border-color: var(--lighterGray);
    background-color: var(--white);
}

.sm {
    border-radius: 4px;
    padding: 6px 60px 6px 60px;
    font-size: 14px;
}

.xs {
    border-radius: 4px;
    font-size: 14px;
    width: 142px;
    height: 30px;
    font-weight: unset;
}

.fullWidth{
    width: 100%;
}

.loadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}
