.title {
    color: var(--gray);
    font-size: 16px;
    font-weight: bold;
}

.modal-header {
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #f4f4f4;
    height: 50px;
}

.main-content {
    padding: 15px;
}

.details-content {
    display: flex;
    gap: 20px;
}

.details-content-section {
    flex: 1;
    height: calc(100% - 100px);
}

.details-content-section-body {
    background-color: #f4f4f4;
    padding: 10px;
    max-height: 250px;
    min-height: 250px;
    border: 1px solid #d5d5d5;
    white-space: pre-wrap;
    overflow: auto;
}


.modal-footer{
    margin: auto 15px 15px auto;
    height: 50px;
}

.mainframe-body-footer-buttons {
    display: flex;
    justify-content: end;
    margin: auto 15px 15px auto;
    /*height: 50px;*/
}

.label {
    font-size: 16px;
    color: var(--darkGray);
}

.labelTextWidth {
    width: 140px;
    display: inline-block;
}

.text {
    font-size: 14px;
    color: var(--gray);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nameContainer {
    min-width: 250px;
}