* {
    /*box-sizing: border-box;*/
}

.mainContent {
    grid-column-start: 2;
    width: 100%;
    height: calc(100vh - 33px);
}

.mainContentGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.dGrid {
    display: grid;
}

.align-self-end {
    align-self: end;
}

.borderRightGrey {
    border-right: 1px solid #D5D5D5;
}

.borderTopGrey {
    border-top: 1px solid #D5D5D5;
}


/* Table */

.tableContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.tableContainer > div:first-child {
    padding: 10px;
    text-align: end !important;
}

.tableButton {
    font-size: 10px;
    border-radius: 8px;
    border-color: rgba(213, 213, 213, 0.43);
    background: #e5e5e5;
    color: #555555;
}

/* Label and Inputs */

.formWrapper {
    width: 100%;
    font-size: var(--text-font-size);
}

.formWrapper .title {
    color: #555555;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 25px;
}

.formWrapper .form {
    width: 100%;
}

.formWrapper .form .input_field {
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    width: 100%;
}

.formWrapper .form .input_field label {
    color: #333333;
    width: 25%;
    font-size: 14px;
    font-weight: bold;
}

.formWrapper .form .input_field input {
    border: 1px solid #D5D5D5;
    width: 300px;
    border-radius: 4px;
    height: 30px;
    padding-left: 7px;
    box-sizing: border-box;
    font-size: var(--text-font-size);
}

.formWrapper .form .input_field .inputCheckbox {
    width: unset;
    height: unset;
    border: 1px solid #D5D5D5;
}

.inputCheckbox {
  cursor: pointer;
}

.formWrapper .form .input_field .textArea {
    height: 60px;
    padding-left: 7px;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    width: 75%;
    resize: none;
    font-size: var(--text-font-size);
}

::-webkit-input-placeholder {
    color: #D5D5D5;
}

:-moz-placeholder { /* Firefox 18- */
    color: #D5D5D5;
}

::-moz-placeholder { /* Firefox 19+ */
    color: #D5D5D5;
}

:-ms-input-placeholder {
    color: #D5D5D5;
}

.registrationUpdateRow .title {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 10px;
}

.registrationUpdateRow label {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
}

.registrationUpdateRow .text {
    color: #555555;;
}

.footerStyle {
    align-self: end;
    padding: 10px;
    border-top: 1px solid #D5D5D5;
}

.footerStyle .marginLeft {
    margin-left: 12px;
}

.searchSelect {
    margin-right: 0;
    margin-left: auto;
}

.table-height {
    height: calc(100% - 47px);
    padding: 0 10px 10px 10px;
}
