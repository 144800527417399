.mainFrameRightSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.homeText {
    font-size: 12px;
    color: #c1c1c1;
    margin-right: 10px;
}

.mainFrameRightSectionTop {
    display: flex;
    height: 50px;
    min-height: 50px;
    flex-direction: column;
    padding: 0 10px;
    border-bottom: 1px solid #D5D5D5;
}

.topMinWidth {
    min-width: 1120px !important;
}

.mainFrameRightSectionTopButtons {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    max-height: 100%;
}

.docDropdownMenu {
    background: initial;
}

.addDocButton {
    height: 30px;
    width: 198px;
    display: flex;
    justify-content: center;
    gap: 4px;
}

.addDocButton > span {
    margin-top: auto;
    margin-bottom: auto;
}

.addDocIcon {
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
}

.mr10 {
    margin-right: 10px;
}


.topButton {
    height: 30px;
    width: 198px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1px;
    cursor: pointer;
}

.listButton{
    height: 30px;
    width: 67px;
    background-image: url("../../../public/images/Icon-View-Button-Shape.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: #54c3f1;
    border-radius: 8px;
}

.tableButton {
    font-size: 12px;
    color: #777777;
    height: 20px;
    border-radius: 8px;
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
}

.tableButtonDisabled {
    font-size: 12px;
    color: #d5d5d5;
    width: 40px;
    height: 20px;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
}

.tableButtonDisabled > img {
    opacity: .2;
}

.bkBlueRow {
    background: #BAE3F9;
}

.fileGridContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    height: 100%;
    overflow: auto;
    border-bottom: var(--defaultBorder)
}

.fileCard {
    position: relative;
    width: 100px;
    justify-content: center;
    justify-items: center;
}

.fileCardLarge {
    position: relative;
    width: 125px;
    justify-content: center;
    justify-items: center;
}

.fileCardImageContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}

.fileCardTitle {
    width: 100%;
    height: 2rem;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileCardCheckbox {
    position: absolute;
    left: 5px;
    top: 5px;
}

.onRowSelected {
    display: flex;
    align-items: center;
}

.onRowSelectedMinWidth {
    display: flex;
    align-items: center;
    min-width: 1130px;
}

.onRowSelected > div:first-child {
    min-width: 250px;
    height: 50px;
    border-right: var(--defaultBorder);
    display: flex;
    align-items: center;
}

.onRowSelected > div:first-child > button {
    margin-left: 10px;
}

.onRowSelected > div:nth-child(2) {
    min-width: 600px;
    height: 50px;
    border-right: var(--defaultBorder);
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.onRowSelected > div:nth-child(2) > button:first-child {
    margin-left: 10px;
}

.onRowSelected > div:nth-child(3) {
    min-width: 160px;
    margin-left: 10px;
}

.onRowSelected > div:nth-child(3) > button{
    width: 151px !important;
}

.breadcrumbBarRight {
  border-bottom: 1px solid #D5D5D5;
  flex: 1;
}

.onSelectedBreadcrumbBar {
  display: flex;
  min-width: 1141px;
}


.box {
    min-height: 30px;
    height: 30px;
    border-bottom: 1px solid #D5D5D5;
    width: 100%;
    /*padding: 0 20px;*/
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 12px;
    /*justify-content: flex-start;*/
}

.boxLeft {
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.boxRight {
    display: flex;
    background: #D5D5D5;
    height: 30px;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
}

.folderName {
    padding-left: 7px;
    line-height: initial;
    font-size: 11px;
    color: var(--midGray);
}

.docName {
    line-height: initial;
}

.attachDocumentButton {
    margin-right: 15px;
}

mark {
    background-color: rgb(252, 228, 214);
}
