.mainframe {
    padding-top: 74px;
    height: calc(100vh - 3px);
    grid-column-start: 2;
    overflow: hidden;
}

.mainFrameBody {
  grid-column-start: 2;
  display: flex;
  overflow: auto;
  height: calc(100vh - 105px);
}

.mainframe button {
    cursor: pointer;
}

.mainframe-leftsection {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: var(--defaultBorder);
}

.mainframe-rightsection {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.class-table table {
    width: 100%;
    border-left: 1px solid #D5D5D5;
    border-top: 1px solid #D5D5D5;
    border-spacing: 0;
}

.class-table table thead {
    background-color: #F2F2F2;
    text-align: left;
}

.class-table table thead th {
    font-weight: normal;
    border-right: 1px solid #D5D5D5;
    border-bottom: 1px solid #D5D5D5;
    padding-left: 5px;
}

.class-table table td {
    border-right: 1px solid #D5D5D5;
    border-bottom: 1px solid #D5D5D5;
    padding: 0 2px;
    padding-left: 5px;
}

.table-button {
    font-size: 10px;
    border-radius: 8px;
    border-color: rgba(213, 213, 213, 0.43);
    background: #e5e5e5;
    color: #555555;
}

.rightframe-header {
    height: 40px;
    min-height: 40px;
    background: #F4F4F4;
    font-size: 16px;
    color: var(--darkGray);
    font-weight: bold;
    display: flex;
    align-items: center;
}

.rightframe-body {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.rightframe-body-content {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    overflow: auto
}

.rightframe-body-content button {
    min-width: 200px;
    width: 200px;
}

.rightframe-body-content input[type=checkbox] {
    margin-right: 20px;
}

.label {
    font-size: 16px;
    color: var(--darkGray);
    margin-bottom: 10px;
}

.text {
    font-size: 14px;
    color: var(--gray);
}

.font-small {
    font-size: 12px;
}

.footer {
    padding: 10px;
    height: fit-content;
    display: flex;
    align-items: flex-end;
}

.footers {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.item-width {
    width: 670px;
    min-width: 670px;
}

.select-width {
    width: 450px;
    min-width: 450px;
}

.templateSearchInputContainer {
    display: flex;
    justify-content: flex-end;
}

.templateSearchInput {
    display: flex;
    flex-direction: row;
}

.checkbox {
  cursor: pointer;
}

.margin-bottom {
  margin-bottom: 10px;
}
