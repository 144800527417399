
.mainframe-body-content {
    min-height: 580px;
    min-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    border: var(--defaultBorder);
}

.label {
    font-size: 16px;
    color: var(--darkGray);
    margin: 15px 0 0 0;
}

.title {
    color: var(--gray);
    font-size: 18px;
    font-weight: bold;
}

.text {
    font-size: 14px;
    color: var(--gray);
}

.font-small {
    font-size: 12px;
}

.modal-header {
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #f4f4f4;
}

.modal-body {
    margin: 0 30px 30px 30px;
    height: 100%;
}

.modal-foooter{
    margin-top: auto;
}
