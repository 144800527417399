.detail-row-container {
    background: var(--primaryLight);
}

.detail-row {
    height: 40px;
    background: var(--primaryLight);
    display: flex;
    align-items: center;
    border: var(--defaultBorder);
    padding-left: 50px;
}

.detail-row label {
    font-size: 14px;
    margin-left: 30px;
    color: #181d1f;
}

.detail-row span {
    font-size: 14px;
    color: #181d1f;
}

.detail-row > div:nth-child(1) {
    min-width: 180px;
}

.detail-row > div:nth-child(2) {
    min-width: 180px;
}

.detail-row > div:nth-child(3) {
    min-width: 180px;
}

.detail-row > div:nth-child(4) {
    min-width: 180px;
}

.loading-row {
    height: 40px;
    padding-right: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
