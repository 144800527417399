.groupLabel {
  width: 100%;
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.group-name-editable-input {
  font-size: 13px;
  color: #808080;
}

.group-name-editable-input:focus {
  outline: none;
}