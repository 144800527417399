.modal-header {
  display: flex;
  justify-content: center;
  padding: 10px;
  background: #f4f4f4;
}

.modal-body {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.title {
  color: var(--gray);
  font-size: 16px;
  font-weight: bold;
}

.form-row {
  display: flex;
  padding-bottom: 15px;
}

.form-col {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 20px;
}

.label {
  font-size: 16px;
  color: var(--darkGray);
  display: flex;
  justify-content: space-between;
}

.form-left {
  min-width: 180px;
}

.form-right {
  min-width: 280px;
}

.form-right-detail {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  padding-left: 8px;
}

.required-asterisk {
  color: red;
  font-size: 16px;
  padding-right: 12px;
}

.info {
  position: relative;
}

.info-button {
  width: 20px !important;
  margin-left: 10px;
}

.info-bubble {
  position: absolute;
  top: -46px;
  left: 72px;
  font-size: 14px;
  color: #fff;
  background: #54C3F1;
  padding: 20px;
  border-radius: 10px;
  width: 350px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-out;
}

.info-bubble::after {
  content: "";
  border: 20px solid transparent;
  border-right: 31px solid #54C3F1;
  border-left: 0;
  position: absolute;
  /* POSITION THE TAIL */
  left: -23px;
  top: 56px;
  margin: -21px 0 0 -8px;
}

.info-button:hover + .info-bubble {
  visibility: visible;
  transition: all 0.2s ease-in;
  opacity: 1;
}

.no-padding-bottom {
  padding-bottom: 0;
}